import React from "react";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import "../Styles/UserJombotron.css";
import Vector from "../../Images/Vector.png";

const UserJumbotron = ({ member }) => {
  const { totalT, upComingT } = useGlobalContext();
  // const member = JSON.parse(localStorage.getItem("member"));
  // console.log(member);
  return (
    <div className="user-jumbotron">
      <div className="container d-flex jumbo-container">
        <div className="jumbo-list">
          <span className="jumbo-item">Upcoming</span>
          <span className="jumbo-item-1">{upComingT}</span>
        </div>
        <div className="jumbo-list">
          <span className="jumbo-item">Total Trips</span>
          <span className="jumbo-item-1">{totalT}</span>
        </div>
        {member?.membershipType && (
          <div className="jumbo-list-1">
            <div className="jumbo-item Essential">
              {/* <img src={Vector} alt="" /> */}
              <span>{member?.membershipType}</span>
            </div>
            <span
              className="jumbo-item-2"
              dangerouslySetInnerHTML={{ __html: member?.benefits }}
            />
            {/* <span className="jumbo-item-2">
              1 Private airport return transfers​
            </span> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserJumbotron;
