import React, { useState } from "react";
import "../Styles/TripReview.css";
import Upload from "../../Images/QuestionaireImg/Upload.png";
import axios from "../../Api/axios";

import { Container, Row, Col } from "react-bootstrap";

import { toast } from "react-toastify";
const TRIP_REVIEW = "/trips/trip-review";

const TripReview = ({ _id }) => {
  console.log(_id);
  const [isLoading, setIsLoading] = useState(false);
  const [body, setBody] = useState("");
  const [image, setImage] = useState([]);

  const imgSizes = image?.find((item) => item?.size > 2000000);
  const imgSizing = () => {
    if (imgSizes) {
      return toast.error(
        "file size is too large, each image should not exceed 2mb"
      );
    }
  };
  // console.log(imgSizes);

  const validateForm = () => {
    let formIsValid = true;

    if (!body) {
      formIsValid = false;
    }
    if (!image) {
      formIsValid = false;
    }

    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return toast("enter title and image");
    }

    // if (imgSizes) {
    //   return toast.error(
    //     "an image(images) size is too large, image should not exceed 2mb"
    //   );
    // }
    if (imgSizing()) {
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("_id", _id);
    image.forEach((file) => formData.append("image", file)); // multiple image
    // formData.append("image", image);
    formData.append("body", body);

    console.log([...formData]);
    try {
      const response = await axios.post(TRIP_REVIEW, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${JSON.parse(localStorage.getItem("data1"))}`,
        },
      });
      setIsLoading(false);
      setImage([]);
      if (response?.data.msg) {
        toast("Successfully Sent!");
        event.target.reset();
      }
    } catch (error) {
      if (!error?.response) {
        toast.error("No Server Response");
        setIsLoading(false);
      } else if (error.response?.status === 500) {
        // setErrMsg("");
        toast.error("File too large, image should not be more than 2mb");
        setIsLoading(false);
      }
    }
  };

  // const handleImage = (event) => {
  //   setImage(event.target.files[0]);
  // };

  // upload multiple images
  const handleImage = (event) => {
    setImage([...image, ...event.target.files]);
  };

  const removeFile = (key) => {
    let newFiles = image.filter((file, index) => key !== index);
    setImage(newFiles);
  };

  return (
    <div className="container trip-review-container">
      <h2>Trip Review</h2>
      <section className="section-trip-review">
        <form className="form-row my-4" onSubmit={handleSubmit}>
          <div className="upload-wrap mt-3 mb-5">
            <label forhtmlFor="inputTag" className="upload-label">
              <img src={Upload} alt="" />
              Input File
              <input
                multiple
                id="inputTag"
                type="file"
                className="upload-input"
                onChange={handleImage}
                // multiple="multiple"
              />
              <span id="imageName"></span>
            </label>

            <span>
              Max file size 2MB. <br /> CTRL+ click to select multiple images
            </span>
          </div>
          {/* <span>CTRL+ click to select multiple images</span> */}

          <Container>
            <Row className=" text-center">
              {image?.map((file, index) => (
                <Col lg="3" md="4" sm="6" xs="6" className="my-2">
                  <img
                    key={index}
                    style={{
                      height: "100px",
                      width: "100%",
                    }}
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                  />
                  <span
                    style={{
                      border: "1px solid red",
                      color: "orangered",
                      cursor: "pointer",
                    }}
                    className="px-2"
                    onClick={() => removeFile(index)}
                  >
                    x
                  </span>
                </Col>
              ))}
            </Row>
          </Container>

          <div className="text-area-wrap">
            <label htmlFor="" className="my-3">
              Your travel Experience
            </label>
            <textarea
              className="text-field"
              placeholder="Enter your review of your travel expereince"
              onChange={(e) => setBody(e.target.value)}
            ></textarea>
          </div>
          <div className="btn-container">
            <button type="submit" className="btn btn-primary">
              {isLoading ? "sending..." : "submit"}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default TripReview;
