import React from "react";
import "../Styles/OverView.css";
import Forward1 from "../../Images/UserProfileImg/forward1.png";
import Forward2 from "../../Images/UserProfileImg/forward2.png";
import Forward3 from "../../Images/UserProfileImg/forward3.png";
import Forward4 from "../../Images/UserProfileImg/forward4.png";
import Forward5 from "../../Images/UserProfileImg/forward5.png";
import Rectangle from "../../Images/Rectangle.jpg";
import moment from "moment/moment";
import { useState, useEffect } from "react";

const OverView = ({ trip, flightDetail }) => {
  const [flightDetails, setFlightDetails] = useState([]);
  useEffect(() => {
    setFlightDetails(flightDetail ? flightDetail : []);
  }, [flightDetail]);

  // sort by date
  const newFlightDetails = flightDetail?.sort(
    (a, b) => new Date(a?.date) - new Date(b?.date)
  );

  // console.log(newFlightDetails);
  return (
    <div className="over-view-wrapper">
      <div className="container">
        <h2 className="ov_header">{trip?.title}</h2>
        {flightDetails.length === 0 ? (
          <p className="ov_header1">No Flight Details Yet!</p>
        ) : (
          <section className="section-content">
            <div className="row-block">
              {newFlightDetails[0]?.location && (
                <div className="block-item">
                  <h6>
                    {newFlightDetails[0]?.location &&
                      newFlightDetails[0]?.location}
                  </h6>
                  <h4>
                    {newFlightDetails[0]?.date &&
                      moment(newFlightDetails[0]?.date).format("ll")}
                  </h4>
                  <p>
                    {newFlightDetails[0]?.date &&
                      moment(newFlightDetails[0]?.date)
                        .format("dddd")
                        .slice(0, 3)}
                  </p>
                </div>
              )}
              {newFlightDetails[1]?.location && (
                <div className="block-item-1">
                  <div className="img-forward">
                    <img src={Forward1} alt="" />
                  </div>
                </div>
              )}
              {newFlightDetails[1]?.location && (
                <div className="block-item">
                  <h6>
                    {newFlightDetails[1]?.location &&
                      newFlightDetails[1]?.location}
                  </h6>
                  <h4>
                    {newFlightDetails[1]?.date &&
                      moment(newFlightDetails[1]?.date).format("ll")}
                  </h4>
                  <p>
                    {newFlightDetails[1]?.date &&
                      moment(newFlightDetails[1]?.date)
                        .format("dddd")
                        .slice(0, 3)}
                  </p>
                </div>
              )}
              {newFlightDetails[2]?.location && (
                <div className="block-item-1">
                  <div className="img-forward">
                    <img src={Forward2} alt="" />
                  </div>
                </div>
              )}
              {newFlightDetails[2]?.location && (
                <div className="block-item">
                  <h6>
                    {newFlightDetails[2]?.location &&
                      newFlightDetails[2]?.location}
                  </h6>
                  <h4>
                    {newFlightDetails[2]?.date &&
                      moment(newFlightDetails[2]?.date).format("ll")}
                  </h4>
                  <p>
                    {moment(
                      newFlightDetails[2]?.date && newFlightDetails[2]?.date
                    )
                      .format("dddd")
                      .slice(0, 3)}
                  </p>
                </div>
              )}
            </div>
            <div className="row-divider">
              {newFlightDetails[3]?.location && (
                <div className="divider-img">
                  <img src={Forward3} alt="" />
                </div>
              )}
            </div>
            <div className="row-block">
              <div
                style={{
                  visibility: !newFlightDetails[5]?.location && "hidden",
                }}
                className="block-item"
              >
                <h6>
                  {newFlightDetails[5]?.location &&
                    newFlightDetails[5]?.location}
                </h6>
                <h4>
                  {newFlightDetails[5]?.date &&
                    moment(newFlightDetails[5]?.date).format("ll")}
                </h4>
                <p>
                  {newFlightDetails[5]?.date &&
                    moment(newFlightDetails[5]?.date)
                      .format("dddd")
                      .slice(0, 3)}
                </p>
              </div>
              <div
                style={{
                  visibility: !newFlightDetails[5]?.location && "hidden",
                }}
                className="block-item-1"
              >
                <div className="img-forward">
                  <img src={Forward5} alt="" />
                </div>
              </div>
              <div
                style={{
                  visibility: !newFlightDetails[4]?.location && "hidden",
                }}
                className="block-item"
              >
                <h6>
                  {newFlightDetails[4]?.location &&
                    newFlightDetails[4]?.location.slice(0, 4)}
                </h6>
                <h4>
                  {newFlightDetails[4]?.date &&
                    moment(newFlightDetails[4]?.date).format("ll")}
                </h4>
                <p>
                  {newFlightDetails[4]?.date &&
                    moment(newFlightDetails[4]?.date)
                      .format("dddd")
                      .slice(0, 3)}
                </p>
              </div>
              <div
                style={{
                  visibility: !newFlightDetails[4]?.location && "hidden",
                }}
                className="block-item-1"
              >
                <div className="img-forward">
                  <img src={Forward4} alt="" />
                </div>
              </div>
              <div
                style={{
                  visibility: !newFlightDetails[3]?.location && "hidden",
                }}
                className="block-item"
              >
                <h6>
                  {newFlightDetails[3]?.location &&
                    newFlightDetails[3]?.location.slice(0, 4)}
                </h6>
                <h4>
                  {newFlightDetails[3]?.date &&
                    moment(newFlightDetails[3]?.date).format("ll")}
                </h4>
                <p>
                  {newFlightDetails[3]?.date &&
                    moment(newFlightDetails[3]?.date)
                      .format("dddd")
                      .slice(0, 3)}
                </p>
              </div>
            </div>
            {/*  */}
            <div className="row-divider1">
              {newFlightDetails[6]?.location && (
                <div className="divider-img">
                  <img src={Forward3} alt="" />
                </div>
              )}
            </div>
            {/*  */}
            <div className="row-block">
              {newFlightDetails[6]?.location && (
                <div className="block-item">
                  <h6>
                    {newFlightDetails[6]?.location &&
                      newFlightDetails[6]?.location}
                  </h6>
                  <h4>
                    {newFlightDetails[6]?.date &&
                      moment(newFlightDetails[6]?.date).format("ll")}
                  </h4>
                  <p>
                    {newFlightDetails[6]?.date &&
                      moment(newFlightDetails[6]?.date)
                        .format("dddd")
                        .slice(0, 3)}
                  </p>
                </div>
              )}
              {newFlightDetails[7]?.location && (
                <div className="block-item-1">
                  <div className="img-forward">
                    <img src={Forward1} alt="" />
                  </div>
                </div>
              )}
              {newFlightDetails[7]?.location && (
                <div className="block-item">
                  <h6>
                    {newFlightDetails[1]?.location &&
                      newFlightDetails[1]?.location}
                  </h6>
                  <h4>
                    {newFlightDetails[7]?.date &&
                      moment(newFlightDetails[7]?.date).format("ll")}
                  </h4>
                  <p>
                    {newFlightDetails[7]?.date &&
                      moment(newFlightDetails[7]?.date)
                        .format("dddd")
                        .slice(0, 3)}
                  </p>
                </div>
              )}
              {newFlightDetails[8]?.location && (
                <div className="block-item-1">
                  <div className="img-forward">
                    <img src={Forward2} alt="" />
                  </div>
                </div>
              )}
              {newFlightDetails[8]?.location && (
                <div className="block-item">
                  <h6>
                    {newFlightDetails[8]?.location &&
                      newFlightDetails[8]?.location}
                  </h6>
                  <h4>
                    {newFlightDetails[8]?.date &&
                      moment(newFlightDetails[8]?.date).format("ll")}
                  </h4>
                  <p>
                    {moment(
                      newFlightDetails[8]?.date && newFlightDetails[8]?.date
                    )
                      .format("dddd")
                      .slice(0, 3)}
                  </p>
                </div>
              )}
            </div>
          </section>
        )}
        {/* {newFlightDetails.length === 0 ? (
          ""
        ) : (
          <section className="section-content">
            
          </section>
        )} */}
        {/*  */}
        <section style={{ marginBottom: "-10px" }} className="section-image">
          <div className="section-img-wrap">
            <img
              src={trip?.image?.link ? trip?.image?.link : Rectangle}
              alt=""
            />
          </div>
        </section>
        <section style={{ marginTop: "2rem" }} className="section-decs">
          <div className="section-decs-wrap">
            <p>{trip?.description}</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OverView;
