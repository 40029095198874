import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import Logo from "../../Images/TKE_logo.png";
import Frame from "../../Images/Frame.png";
import "../Styles/UserHeader.css";
import ChangePasswordImg from "../../Images/UserProfileImg/ChangePassword.png";
import BespoKeImg from "../../Images/UserProfileImg/bespoke.png";
import LogoutImg from "../../Images/UserProfileImg/Logout.png";
import DestinationLogo from "../../Images/UserProfileImg/passport.png";
import SettingsImg from "../../Images/UserProfileImg/setup.png";
import InitiateTrip from "../../Images/QuestionaireImg/InitiateTrip.png";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";

const UserHeader = () => {
  const { namedUser } = useGlobalContext();
  const [tripOption, setTripOption] = useState(false);
  const [userSetup, setUserSetup] = useState(false);
  // const firstName1 = JSON.parse(localStorage.getItem("firstName1"));
  const toggleTripOption = () => {
    setTripOption((prev) => !prev);
    setUserSetup(false);
  };
  const toggleUserSetup = () => {
    setUserSetup((prev) => !prev);
    setTripOption(false);
  };

  const name1 = JSON.parse(localStorage.getItem("userName"));
  console.log(name1);

  return (
    <div className="user-header mt-4 mb-5">
      <Link to={"/"}>
        {" "}
        <img src={Logo} alt="" className="user-page-logo" />
      </Link>
      <div className="trip-account-wrap">
        <div className="trip-link">
          <img src={InitiateTrip} alt="" className="mx-1" />
          <span onClick={toggleTripOption}>
            Initiate a new trip <FiChevronDown className="iconDown" />
          </span>
          {tripOption && (
            <div className="trip-link-options">
              <Link
                to={"/destination/celebration"}
                className="trip-link-wrapper d-flex"
              >
                <img src={DestinationLogo} alt="" />
                <p>Destination Celebration</p>
              </Link>
              <Link to={"/travel/bespoke"} className="trip-link-wrapper d-flex">
                <img src={BespoKeImg} alt="" />
                <p>Bespoke Travel</p>
              </Link>
            </div>
          )}
        </div>
        <div className="user-settings" onClick={toggleUserSetup}>
          <img src={Frame} alt="" className="fame-logo" />
          <div className="user-option">
            <span className="d-flex" style={{ textTransform: "capitalize" }}>
              {namedUser ? namedUser : name1}
              <FiChevronDown
                style={{ fontSize: "1.5rem" }}
                className="iconDown200"
              />
            </span>
            {userSetup && (
              <div className="user-option-list">
                <Link
                  to={"/profile/settings"}
                  className="user-option-wrapper d-flex"
                >
                  <img src={SettingsImg} alt="" />
                  <p>Your profile</p>
                </Link>
                <Link
                  to={"/change-password"}
                  style={{ textDecoration: "none" }}
                  className="user-option-wrapper d-flex"
                >
                  <img src={ChangePasswordImg} alt="" />
                  <p>Change Password</p>
                </Link>
                <div className="user-option-wrapper d-flex">
                  <img src={LogoutImg} alt="" />
                  <p
                    onClick={() => {
                      // delete everythin
                      window.localStorage.clear();
                      // delete something specific
                      // localStorage.removeItem("data1");
                      // localStorage.removeItem("banner");
                      // localStorage.removeItem("emailId");
                      // localStorage.removeItem("firstName1");
                      window.location.reload();
                    }}
                  >
                    Log out
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
