import React, { useEffect } from "react";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import "../Styles/TripList.css";
import Rectangle from "../../Images/Rectangle.jpg";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

// const tripList = [
//   {
//     id: 1,
//     status: "up coming",
//     Frame: Rectangle,
//     startTripDay: "Wed",
//     startTripDate: "May 06, 2022",
//     endTripDay: "Sun",
//     endTripDate: "May 07, 2022",
//     place: "Santorini, Greece",
//   },
//   {
//     id: 2,
//     status: "older",
//     Frame: Rectangle,
//     startTripDay: "Wed",
//     startTripDate: "May 06, 2022",
//     endTripDay: "Sun",
//     endTripDate: "May 07, 2022",
//     place: "Santorini, Greece",
//   },
//   {
//     id: 3,
//     status: "older",
//     Frame: Rectangle,
//     startTripDay: "Wed",
//     startTripDate: "May 06, 2022",
//     endTripDay: "Sun",
//     endTripDate: "May 07, 2022",
//     place: "Santorini, Greece ",
//   },
//   {
//     id: 4,
//     status: "older",
//     Frame: Rectangle,
//     startTripDay: "Wed",
//     startTripDate: "May 06, 2022",
//     endTripDay: "Sun",
//     endTripDate: "May 07, 2022",
//     place: "Santorini, Greece",
//   },
//   {
//     id: 5,
//     status: "older",
//     Frame: Rectangle,
//     startTripDay: "Wed",
//     startTripDate: "May 06, 2022",
//     endTripDay: "Sun",
//     endTripDate: "May 07, 2022",
//     place: "Santorini, Greece",
//   },
// ];

const TripList = ({ singleUserTrips }) => {
  const { setUpComingT } = useGlobalContext();

  function isDateInFuture(date) {
    // return moment(date).format("lll") > moment(Date.now).format("lll");
    return new Date(date).getTime() < new Date().getTime();
  }
  const upComingTrip = singleUserTrips?.filter((item) => {
    if (!isDateInFuture(item?.overview?.start_date)) {
      return item;
    }
    return;
  });

  const newUpComing = upComingTrip?.sort(
    (a, b) =>
      new Date(a?.overview?.start_date) - new Date(b?.overview?.start_date)
  );

  const olderTrips = singleUserTrips?.filter((item) => {
    if (isDateInFuture(item?.overview?.start_date)) {
      return item;
    }
    return;
  });

  const trips = [...newUpComing, ...olderTrips];
  // console.log(newUpComing);

  useEffect(() => {
    setUpComingT(upComingTrip.length);
  }, [upComingTrip, setUpComingT]);
  // console.log(upComingT);
  return (
    <div className="user-trip-list">
      <h4 className="trip-head mb-2">Trips</h4>
      <div className="my-5">
        {trips.map((item, index) => {
          return (
            <div key={item._id} className="trip-list my-4">
              <div className={`rounded-before`}>
                <span
                  className={`${
                    singleUserTrips.length - 1 === index ? "" : "rounded-line"
                  }`}
                ></span>
              </div>
              {/* <div className="trip-content d-flex"> */}
              <Link
                className={`trip-content d-flex ${
                  new Date(item?.overview?.start_date).getTime() <
                  new Date().getTime()
                    ? "backGround"
                    : ""
                }`}
                to={`/user/trip/${item._id}`}
              >
                <div className="trip-logo-name">
                  <img
                    src={
                      item?.overview?.image?.link
                        ? item?.overview?.image?.link
                        : Rectangle
                    }
                    alt=""
                  />
                  <span>{item?.overview?.title}</span>
                </div>

                <div className="trip-start-end">
                  {new Date(item?.overview?.start_date).getTime() >
                    new Date().getTime() && (
                    <div className="up-coming-view">
                      <span>Up coming</span>
                    </div>
                  )}
                  <div className="trip-start">
                    <span className="span-one">
                      {moment(item?.overview?.start_date)
                        .format("dddd")
                        .slice(0, 3)}
                    </span>
                    <span className="span-two">
                      {moment(item?.overview?.start_date).format("ll")}
                    </span>
                  </div>
                  <div className="trip-arrow">&#8594;</div>
                  <div className="trip-end">
                    <span className="span-one">
                      {moment(item?.overview?.end_date)
                        .format("dddd")
                        .slice(0, 3)}
                    </span>
                    <span className="span-two">
                      {moment(item?.overview?.end_date).format("ll")}
                    </span>
                  </div>
                  <div className="trip-arrow new-arrow-mobile">
                    <FiChevronRight />
                  </div>
                </div>
              </Link>
              {/* </div> */}
            </div>
          );
        })}

        {/*  */}
        {/*  */}
      </div>
    </div>
  );
};

export default TripList;
