import React, { useState } from "react";
import Body from "./noAuthArea/Components/Body";
import ContentHead from "./noAuthArea/Components/ContentHead";
import Title from "./noAuthArea/Components/Title";

function Example() {
  const [files, setFiles] = useState([]);

  const handleChange = (event) => {
    setFiles([...files, ...event.target.files]);
  };
  const removeFile = (key) => {
    let newFiles = files.filter((file, index) => key !== index);
    setFiles(newFiles);
  };
  const uploadFiles = async () => {
    const formData = new FormData();
    files.forEach((file) => formData.append("image", file));
    try {
      let uploads = await fetch(
        "https://tke-travel.onrender.com/api/v1/trips/trip-review",
        {
          method: "POST",
          body: formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data1"))}`,
          },
        }
      );
      console.log(await uploads.json());
    } catch (err) {
      console.log(err);
    }
  };
  console.log(files);
  return (
    <Body>
      <div className="travelMain">
        <input type="file" multiple onChange={handleChange} />
        {files.map((file, index) => (
          <div style={{ display: "flex" }}>
            <img
              key={index}
              style={{ height: "auto", width: "60px", marginRight: "10px" }}
              src={URL.createObjectURL(file)}
              alt={file.name}
            />
            <button onClick={() => removeFile(index)}>x</button>
          </div>
        ))}
        <button onClick={uploadFiles}>upload</button>
           
      </div>
    </Body>
  );
}

export default Example;
