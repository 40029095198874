import React, { useState, useEffect } from "react";
import AuthBody from "../Components/AuthBody";
import "../Styles/UserTripPage.css";
import OverView from "../Components/OverView";
import Agreement from "../Components/Agreement";
import Itenary from "../Components/Itenary";
import TravelConfirmation from "../Components/TravelConfirmation";
import Payment from "../Components/Payment";
import TripReview from "../Components/TripReview";
import Visa from "../Components/Visa";
// images //
import Review from "../../Images/review.png";
import AgreementImg from "../../Images/agreement.png";
import ItineraryImg from "../../Images/itinerary.png";
import TravelCertImg from "../../Images/UserProfileImg/travelCert2.png";
import PaymentImg from "../../Images/payment.png";
import TripReviewImg from "../../Images/tripReview.png";
import VisaGroupImg from "../../Images/GroupIcon.png";
import { MdKeyboardBackspace } from "react-icons/md";
import axios from "../../Api/axios";
import { useParams } from "react-router-dom";
import UploadDocuments from "../Components/UploadDocuments";

const tripCategory = [
  {
    id: "1",
    category: "Overview",
    img: Review,
  },
  {
    id: "2",
    category: "Agreements",
    img: AgreementImg,
  },
  {
    id: "3",
    category: "Itinerary",
    img: ItineraryImg,
  },
  {
    id: "4",
    category: "Travel Confirmation",
    img: TravelCertImg,
  },
  {
    id: "5",
    category: "Payment",
    img: PaymentImg,
  },
  {
    id: "6",
    category: "Visa",
    img: VisaGroupImg,
  },
  {
    id: "7",
    category: "Upload Documents",
    img: VisaGroupImg,
  },
  {
    id: "8",
    category: "Trip Review",
    img: TripReviewImg,
  },
];

// const index1 = () => {
//   const localData = JSON.parse(localStorage.getItem("index1"));
//   return localData ? localData : 0;
// };
const UserTripPage = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reLoad, setReLoad] = useState(false);
  const [tripById, setTripById] = useState([]);
  // console.log(tripById);
  const fetchTrips = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/trips/get-all?_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data1"))}`,
        },
      });
      const { data } = response?.data;
      setTripById(data);
      // console.log(data);
      setIsLoading(false);
      // console.log(topReferrals);
    } catch (err) {
      console.log(err.response);
    }
  };
  useEffect(() => {
    fetchTrips();
    // setTrip(tripAll.find((item) => item._id === id));
  }, [id, reLoad]);

  let width = window.innerWidth;

  return (
    <AuthBody>
      <div className="user-trip-page-content">
        <div
          id={`${width <= 450 && value !== 0 ? "disappear" : ""}`}
          className="trip-sidebar-list"
        >
          {tripCategory.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setValue(index);
                  localStorage.setItem("index1", JSON.stringify(index));
                }}
                className={`sidebar-row ${index === value && "active-side"}`}
                style={{
                  display: width <= 450 && value === index ? "none" : "",
                }}
              >
                <div className="div-img">
                  <img src={item.img} alt="review" className="sidebar-img" />
                </div>
                <span>{item.category}</span>
              </div>
            );
          })}
        </div>

        <div className="trip-page-details">
          {width <= 450 && value !== 0 ? (
            <MdKeyboardBackspace
              style={{
                color: "#000",
                fontSize: "1.7rem",
              }}
              className="mt-2 mb-3"
              onClick={() => {
                setValue(0);
              }}
            />
          ) : (
            ""
          )}
          {value === 0 && (
            <OverView
              trip={tripById[0]?.overview}
              flightDetail={tripById[0]?.flightDetails}
            />
          )}
          {value === 1 && <Agreement trip={tripById[0]?.agreements} />}
          {value === 2 && <Itenary trip={tripById[0]?.itinerary} />}
          {value === 3 && (
            <TravelConfirmation trip={tripById[0]?.travelConfirmation} />
          )}
          {value === 4 && <Payment trip={tripById[0]?.payment} />}
          {value === 5 && <Visa trip={tripById[0]?.visa} />}
          {value === 6 && (
            <UploadDocuments
              _id={id}
              trip={tripById[0]?.additionalDocuments}
              trip2={tripById[0]?.additionalDocumentRequests}
              setReLoad={setReLoad}
            />
          )}
          {value === 7 && <TripReview _id={id} />}
        </div>
      </div>
    </AuthBody>
  );
};

export default UserTripPage;
