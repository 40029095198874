import React from "react";
import "../Styles/Agreement.css";
import DocView from "../../Images/UserProfileImg/docViewArrow.png";

const Agreement = ({ trip }) => {
  // console.log(trip);

  const newPaid =
    trip?.filter((item) => {
      return item?.status === "Signed";
    }) || [];
  const newNotPaid =
    trip?.filter((item) => {
      return item?.status === "Not Signed";
    }) || []; // add an empty array as a fallback value

  const trip1 = [...newNotPaid, ...newPaid];

  return (
    <div className="container agreement-container">
      <h2>Agreements</h2>
      <section className="section-agreement">
        {
          // if myArray is undefined or null, display a loading message
          !trip ? (
            <p style={{ margin: "6rem", color: "black" }}>Loading...</p>
          ) : (
            trip1?.map((item, index) => {
              return (
                <div key={index} className="row my-3">
                  <div className="agreement-row-wrap">
                    <span className="col-doc">{item?.title}</span>
                    <span
                      style={{
                        background:
                          item?.status.toLowerCase() === "not signed"
                            ? "rgba(231, 59, 21, 0.1)"
                            : " rgba(31, 112, 3, 0.1)",
                        color:
                          item?.status.toLowerCase() === "not signed"
                            ? "#e73b15"
                            : "#1F7003",
                      }}
                      className="col-sign"
                    >
                      {item?.status.toLowerCase()}
                    </span>
                    <div className="agreement-docs">
                      <a
                        href={
                          item?.document_link?.includes("https://")
                            ? item?.document_link
                            : "https://" + item?.document_link
                        }
                        target="_blank"
                        className="col-doc-view"
                        style={{ textDecoration: "none" }}
                      >
                        View
                      </a>
                      <img src={DocView} alt="" />
                    </div>
                  </div>
                </div>
              );
            })
          )
        }
      </section>
    </div>
  );
};

export default Agreement;
