import React, { createContext, useContext, useState, useEffect } from "react";
import { bespoke1, destinationCelData1, userData } from "./data";
import axios from "../Api/axios";
const FETCH_TRIPS = "/trips/get-all";
// import axios from "../Api/axios";
// const BESPOKE_URL = "/questionnaires/create";

const DataContext = createContext();

export const TKEDataProvider = ({ children }) => {
  // const [userName1, setUserName1] = useState(
  //   JSON.parse(localStorage.getItem("firstName1"))
  // );
  const [tripAll, setTripAll] = useState([]);
  // console.log(userName1);
  const [memberProfile, setMemberProfile] = useState("");
  const [namedUser, setNamedUser] = useState("");
  const [reload, setReload] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData1, setUserData1] = useState(userData);
  const [totalT, setTotalT] = useState(0);
  const [upComingT, setUpComingT] = useState(1);
  const [bespokeData, setBespokeData] = useState(bespoke1);
  const [destinationCelData, setDestinationCelData] =
    useState(destinationCelData1);

  function handleChangeUser(event) {
    const { name, value, type, checked } = event.target;
    setUserData1((prev) => {
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  function handleChangeDestinationCel(event) {
    const { name, value, type, checked } = event.target;
    setDestinationCelData((prev) => {
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  // for Bespoke
  function handleChangeBespoke(event) {
    const { name, value, type, checked } = event.target;
    setBespokeData((prev) => {
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  const newInfo = {
    user: {
      firstName: userData1.firstName,
      lastName: userData1.lastName,
      email: userData1.email,
      dialCode: userData1.dialCode,
      phoneNumber: userData1.phoneNumber,
      gender: userData1.gender,
      age: userData1.age,
    },
    tripType: userData1.tripType,
    tripDetails: {
      whereTo: bespokeData.whereTo,
      needVisa: bespokeData.needVisa,
      whatCity: bespokeData.whatCity,
      daysLikelyToSpend: bespokeData.daysLikelyToSpend,
      anyDateSet: bespokeData.anyDateSet,
      enterDateToTravel: bespokeData.enterDateToTravel,
      enterDateToArrive: bespokeData.enterDateToArrive,
      ideaMonthYear: bespokeData.ideaMonthYear,
      travelForWhat: bespokeData.travelForWhat,
      travelWithWho: bespokeData.travelWithWho,
      numberOfTravellerAdult: bespokeData.numberOfTravellerAdult,
      numberOfTravellerKids: bespokeData.numberOfTravellerKids,
      choiceActivityWhileTravelling: bespokeData.choiceActivityWhileTravelling,
      budgetForEntireTrip: bespokeData.budgetForEntireTrip,
      aboutTrip: bespokeData.aboutTrip,
      bookingTime: bespokeData.bookingTime,
    },
  };
  const newInfo2 = {
    user: {
      firstName: userData1.firstName,
      lastName: userData1.lastName,
      email: userData1.email,
      dialCode: userData1.dialCode,
      phoneNumber: userData1.phoneNumber,
      gender: userData1.gender,
      age: userData1.age,
    },
    tripType: userData1.tripType,
    tripDetails: {
      occationYouAreCeleberating: destinationCelData.occationYouAreCeleberating,
      likeToCeleberteWhat: destinationCelData.likeToCeleberteWhat,
      estimateGuestToCelerateWith:
        destinationCelData.estimateGuestToCelerateWith,
      AdultsCeleberatingWith: destinationCelData.AdultsCeleberatingWith,
      childrenCeleberatingWith: destinationCelData.childrenCeleberatingWith,
      whatCityYouMostLikelyExpectQuests:
        destinationCelData.whatCityYouMostLikelyExpectQuests,
      daysToSpendCeleberating: destinationCelData.daysToSpendCeleberating,
      setDateForCel: destinationCelData.setDateForCel,
      whatTKEServiceDoYouWant: destinationCelData.whatTKEServiceDoYouWant,
      anythingElseAboutCeleberation:
        destinationCelData.anythingElseAboutCeleberation,
      howSoonDoYouWantToBook: destinationCelData.howSoonDoYouWantToBook,
    },
  };
  // console.log();
  return (
    <DataContext.Provider
      value={{
        destinationCelData,
        errMsg,
        isLoading,
        setDestinationCelData,
        handleChangeDestinationCel,
        bespokeData,
        handleChangeBespoke,
        setIsLoading,
        setErrMsg,
        handleChangeUser,
        userData1,
        // userName1,
        // setUserName1,
        setTotalT,
        setUpComingT,
        totalT,
        upComingT,
        namedUser,
        setNamedUser,
        reload,
        setReload,
        memberProfile,
        setMemberProfile,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(DataContext);
};

export default DataContext;
