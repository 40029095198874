import React, { useState } from "react";
import Body from "../Components/Body";
import Title from "../Components/Title";
import axios from "../../Api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const FORGOT_PASSWORD = "/user/forgot-password";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = values;
    if (email === "") {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post("/user/forgot-password", {
        email: email,
      });
      localStorage.setItem("reset100", JSON.stringify(response?.data?.token));
      setErrMsg(response?.data.message);
      if (response?.data.msg) {
        toast(response?.data.msg);
        // console.log(response?.data.msg);
      }
      navigate("/reset");
      setIsLoading(false);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 409) {
        setErrMsg("wrong token");
        toast(err.response?.data.msg);
        setIsLoading(false);
      } else {
        setErrMsg("Registration Failed");
        setIsLoading(false);
      }
    }
  };

  return (
    <Body>
      <div className="login-container p-4">
        <Title title="Forgot Password" />
        <form className="px-2 login-container-form" onSubmit={handleSubmit}>
          <div className="mb-3 pt-3 login-input-wrapper">
            <input
              type="email"
              name="email"
              className="form-control"
              id="exampleInputEmail"
              placeholder="Email"
              onChange={handleChange}
            />
          </div>

          <div className="btn-container-login">
            <button type="submit" className="btn btn-primary">
              {isLoading ? "loading" : "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </Body>
  );
};

export default ForgotPassword;
