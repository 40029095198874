import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Body from "../Components/Body";
import ContentHead from "../Components/ContentHead";
import ProgressBar from "../Components/ProgressBar";
import Title from "../Components/Title";
import "../Styles/TravelQuestionaire.css";
import "../Styles/errorMsg.css";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import { phoneCode } from "../../authArea/Pages/CountryData";

const TravelQuestionaire = () => {
  const navigate = useNavigate();
  const count = 1;
  const { userData1, handleChangeUser } = useGlobalContext();
  const [errors, setError] = useState({});

  // console.log(userData1.phoneNumber);

  const validateForm = () => {
    const { firstName, lastName, email, phoneNumber, gender, age, tripType } =
      userData1;

    let errors = {};
    let formIsValid = true;
    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!regex.test(email)) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email.";
    }

    if (!age) {
      formIsValid = false;
      errors["age"] = "*Date of birth must be filled.";
    }

    if (!phoneNumber || isNaN(phoneNumber)) {
      formIsValid = false;
      errors["phoneNumber"] = "*Please enter your phone number.";
    }

    if (!firstName) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }
    if (!lastName) {
      formIsValid = false;
      errors["lastName"] = "*Please enter your last name.";
    }

    if (!tripType === "celebration" || !tripType === "bespoke") {
      formIsValid = false;
      errors["tripType"] = "*Please select your trip type.";
    }

    if (!gender === "male" || !gender === "female") {
      formIsValid = false;
      errors["gender"] = "*Please select your gender.";
    }

    setError(errors);
    return formIsValid;
  };

  const genderValidation = () => {
    if (!userData1.gender) {
      setError({ gender: "*Please select your gender" });
      return window.scrollTo(0, 0);
    }
  };

  const fireNextStep = (e) => {
    // const { tripType } = userData1;
    e.preventDefault();
    if (validateForm()) {
      if (userData1.gender) {
        if (userData1.tripType === "bespoke") {
          return navigate("/travel/bespoke");
        } else if (userData1.tripType === "celebration") {
          navigate("/destination/celebration");
        } else {
          setError({ tripType: "*Please select your trip option" });
          return window.scrollTo(0, 0);
        }
      } else {
        setError({ gender: "*Please select your gender" });
        return window.scrollTo(0, 0);
      }
    }
    return window.scrollTo(0, 0);
  };
  // gg

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     setIsLoading(true);
  //     const {
  //       firstName,
  //       lastName,
  //       email,
  //       dialCode,
  //       phoneNumber,
  //       gender,
  //       age,
  //       tripType,
  //     } = travelData;

  //     try {
  //       const response = await axios.post(REGISTER_URL, {
  //         firstName,
  //         lastName,
  //         email,
  //         dialCode,
  //         phoneNumber,
  //         gender,
  //         age,
  //         tripType,
  //       });
  //       //   console.log(response.message);
  //       setErrMsg(response?.data.message);
  //       setIsLoading(false);
  //       travelData.tripType === "bespoke"
  //         ? navigate("/Bespoke")
  //         : navigate("/destination");
  //     } catch (err) {
  //       if (!err?.response) {
  //         setErrMsg("No Server Response");
  //         setIsLoading(false);
  //       } else if (err.response?.status === 400) {
  //         setErrMsg("Missing Field Input");
  //         setIsLoading(false);
  //       } else if (err.response?.status === 401) {
  //         setErrMsg("Unauthorized");
  //         console.log(errMsg);
  //         setIsLoading(false);
  //       } else {
  //         setErrMsg("Failed Due To An Error");
  //         setIsLoading(false);
  //       }
  //     }
  //   }
  //   return;
  // };
  // console.log(errors["tripType"]);

  // console.log([...new Set(phoneCode)]);
  // console.log(phoneCode.sort((a, b) => a - b));
  let phoneCode1 = phoneCode?.sort((a, b) => a - b);
  phoneCode1 = ["234", ...new Set(phoneCode)];

  return (
    <Body>
      <div className="travelMain">
        <Title title="TRAVEL QUESTIONNAIRE" />
        <ContentHead />
        <ProgressBar count={count} />
        {Object.keys(errors).length !== 0 ? (
          <div className="errorMsgWrapper">
            {errors["email"] && (
              <div className="errorMsg">{errors["email"]}</div>
            )}
            {errors["firstName"] && (
              <div className="errorMsg">{errors["firstName"]}</div>
            )}
            {errors["lastName"] && (
              <div className="errorMsg">{errors["lastName"]}</div>
            )}
            {errors["phoneNumber"] && (
              <div className="errorMsg">{errors["phoneNumber"]}</div>
            )}
            {/* {errors["age"] && <div className="errorMsg">{errors["age"]}</div>} */}

            {errors["dialCode"] && (
              <div className="errorMsg">{errors["dialCode"]}</div>
            )}
            {errors["tripType"] && (
              <div className="errorMsg">{errors["tripType"]}</div>
            )}
            {errors["gender"] && (
              <div className="errorMsg">{errors["gender"]}</div>
            )}
            {errors["age"] && <div className="errorMsg">{errors["age"]}</div>}
          </div>
        ) : null}
        <form className="px-2">
          <div className="mb-3 d-flex TQCSS justify-content-between firstLastName">
            <div style={{ width: "50%" }}>
              <label style={{ marginLeft: "0.6rem" }} htmlFor="">
                First Name
              </label>
              <input
                required
                type="text"
                name="firstName"
                className="form-control firstLastName-1"
                aria-describedby="emailHelp"
                defaultValue={userData1.firstName}
                onChange={handleChangeUser}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label style={{ marginLeft: "0.6rem" }} htmlFor="">
                Last Name
              </label>
              <input
                required
                type="text"
                name="lastName"
                className="form-control firstLastName-2"
                aria-describedby="emailHelp"
                // placeholder="Last name"
                defaultValue={userData1.lastName}
                onChange={handleChangeUser}
              />
            </div>
          </div>
          <div className="mb-3 pt-3 email-container TQCSS">
            <div style={{ width: "50%" }}>
              <label style={{ marginLeft: "0.6rem" }} htmlFor="">
                Email
              </label>
              <input
                required
                type="email"
                name="email"
                className="form-control email-container-input"
                // placeholder="Email"
                defaultValue={userData1.email}
                onChange={handleChangeUser}
              />
            </div>
          </div>

          <div className="mb-3 pt-3 d-flex justify-content-between number-container TQCSS">
            <div style={{ width: "25%" }}>
              <label style={{ marginLeft: "0.6rem" }} htmlFor="">
                Phone Code
              </label>
              <select
                className="form-select number-container-1"
                defaultValue={userData1.dialCode}
                name="dialCode"
                onChange={handleChangeUser}
              >
                {phoneCode1?.map((item, index) => (
                  <option key={index} value={`+{${item}}`}>
                    +{item}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: "75%" }}>
              <label style={{ marginLeft: "0.8rem" }} htmlFor="">
                Phone number
              </label>
              <input
                required
                type="number"
                name="phoneNumber"
                className="form-control number-container-2"
                aria-describedby="emailHelp"
                defaultValue={userData1.phoneNumber}
                onChange={handleChangeUser}
              />
            </div>
          </div>
          <div className="mb-3 pt-3 d-flex justify-content-between firstLastName TQCSS">
            <div style={{ width: "50%" }} className="d-flex flex-column">
              <label style={{ marginLeft: "0.6rem" }} htmlFor="">
                Gender
              </label>
              <select
                className="form-select firstLastName-1"
                defaultValue={userData1.gender}
                name="gender"
                onChange={handleChangeUser}
                required={true}
              >
                <option>Select Option...</option>
                <option value="male">male</option>
                <option value="female">female</option>
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <label style={{ marginLeft: "0.6rem" }} htmlFor="">
                Date Of Birth
              </label>
              <input
                type="date"
                name="age"
                className="form-control firstLastName-2"
                aria-describedby="emailHelp"
                defaultValue={userData1.age}
                onChange={handleChangeUser}
              />
            </div>
          </div>
          <div className="mb-3 pt-3 email-container TQCSS">
            <div style={{ width: "100%" }}>
              <label style={{ marginLeft: "0.6rem" }} htmlFor="">
                Trip options
              </label>
              <select
                className="form-select email-container-input3"
                value={userData1.tripType}
                name="tripType"
                onChange={handleChangeUser}
              >
                <option>Select Option... </option>
                <option value="celebration">destination travel</option>
                <option value="bespoke">bespoke travel</option>
              </select>
            </div>
          </div>
          <div className="btn-container">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={fireNextStep}
            >
              Next
            </button>
          </div>
          {/* <div className="btn-container">
            <button type="submit" className="btn btn-primary">
              {isLoading ? "loading... " : "Next"}
            </button>
          </div> */}
        </form>
      </div>
    </Body>
  );
};

export default TravelQuestionaire;
