import React from "react";
import "../Styles/ContentHead.css";

const ContentHead = () => {
  return (
    <div className="contentHead mt-2 mb-3">
      To give the authentic and personalized travel experience that you expect,
      we need to know and understand what you want. Please complete the
      questionnaire below.
    </div>
  );
};

export default ContentHead;
