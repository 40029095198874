import React from "react";
import "../Styles/Body.css";
import Footers from "./Footers";
import Headers from "./Headers";

const Body = ({ children }) => {
  return (
    <div className="container body-wrapper">
      <Headers />
      <div className="travel-questionaire">{children}</div>
      <Footers />
    </div>
  );
};

export default Body;
