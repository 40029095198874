import React from "react";
import "../Styles/Footer.css";

const Footers = () => {
  return (
    <div className="footer-container">
      <span>© 2022 All right reserved by The Keona Experience</span>
    </div>
  );
};

export default Footers;
