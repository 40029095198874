import React, { useState } from "react";
import Body from "../Components/Body";
import ContentHead from "../Components/ContentHead";
import ProgressBar from "../Components/ProgressBar";
import Title from "../Components/Title";
import "../Styles/BespokeQuestionaire.css";
import { useNavigate, useParams } from "react-router-dom";
import BespokePage1 from "../Components/BespokePage1";
import BespokePage2 from "../Components/BespokePage2";

const BespokeQuestionaire = () => {
  const { bespoke } = useParams();
  // console.log(bespoke);
  const [count, setCount] = useState(2);
  // const pageX = 1;
  return (
    <Body>
      <div>
        <Title title="BESPOKE TRAVEL QUESTIONNAIRE" />
        <ContentHead />
        <ProgressBar count={count} />
        {count === 2 && <BespokePage1 setCount={setCount} />}
        {count === 3 && <BespokePage2 bespoke={bespoke} setCount={setCount} />}
      </div>
    </Body>
  );
};

export default BespokeQuestionaire;
