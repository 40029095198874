import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Images/TKE_logo.png";
import "../Styles/Headers.css";

const Headers = () => {
  const navigate = useNavigate();
  return (
    <div className="headers-container mt-4">
      <a href="https://thekeona.com/">
        <img
          // onClick={() => navigate("https://thekeona.com/")}
          src={Logo}
          alt="Logo"
          className="header-logo"
        />
      </a>
    </div>
  );
};

export default Headers;
