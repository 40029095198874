import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import axios from "../../Api/axios";
import { toast } from "react-toastify";

const DESTINATION_URL = "/questionnaires/create";

const DestinationQuestionairePage2 = ({ celebration11, setCount }) => {
  const [whatTKE1, setwhatTKE1] = useState("");
  const [whatTKE2, setwhatTKE2] = useState("");
  const [whatTKE3, setwhatTKE3] = useState("");
  const [whatTKE4, setwhatTKE4] = useState("");
  const [whatTKE5, setwhatTKE5] = useState("");
  const [errors, setError] = useState({});
  const tkeOptions = [whatTKE1, whatTKE2, whatTKE3, whatTKE4, whatTKE5];
  // console.log(tkeOptions);
  const navigate = useNavigate();
  const { destinationCelData, handleChangeDestinationCel, userData1 } =
    useGlobalContext();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // validate
  const authToken = JSON.parse(localStorage.getItem("data1"));
  // console.log(authToken);

  const validateForm = () => {
    const { setDateForCel, daysToSpendCeleberating, howSoonDoYouWantToBook } =
      destinationCelData;

    let errors = {};
    let formIsValid = true;

    // const regex =
    //   /(0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](0?[1-9]|1[0-2])[^\w\d\r\n:](\d{4}|\d{2})/;

    // if (!regex.test(setDateForCel)) {
    //   formIsValid = false;
    //   errors["setDateForCel"] = "*Please enter a valid date";
    // }
    if (!setDateForCel) {
      formIsValid = false;
      errors["setDateForCel"] = "*Please enter a valid date";
    }
    if (!daysToSpendCeleberating) {
      formIsValid = false;
      errors["daysToSpendCeleberating"] =
        "*Please enter days expected to celebrate.";
    }
    if (!howSoonDoYouWantToBook) {
      formIsValid = false;
      errors["howSoonDoYouWantToBook"] =
        "*how soon do you want to book?, please select an option.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return window.scrollTo(0, 0);
    setIsLoading(true);

    try {
      const response = await axios.post(
        DESTINATION_URL,
        {
          user: {
            firstName: userData1.firstName,
            lastName: userData1.lastName,
            email: userData1.email,
            dialCode: userData1.dialCode ? userData1.dialCode : "234",
            phoneNumber: userData1.phoneNumber,
            gender: userData1.gender,
            age: userData1.age,
          },
          tripType: celebration11 ? celebration11 : userData1.tripType,
          tripDetails: {
            occationYouAreCelebrating:
              destinationCelData.occationYouAreCeleberating,
            likeToCelebrateWhat: destinationCelData.likeToCeleberteWhat,
            estimateGuestToCelebrateWith:
              destinationCelData.estimateGuestToCelerateWith,
            AdultsCeleberatingWith: destinationCelData.AdultsCeleberatingWith,
            childrenCelebratingWith:
              destinationCelData.childrenCeleberatingWith,
            whatCityYouMostLikelyExpectQuests:
              destinationCelData.whatCityYouMostLikelyExpectQuests,
            daysToSpendCelebrating: destinationCelData.daysToSpendCeleberating,
            setDateForCel: destinationCelData.setDateForCel,
            // whatTKEServiceDoYouWant: destinationCelData.whatTKEServiceDoYouWant,
            whatTKEServiceDoYouWant: tkeOptions,
            anythingElseAboutCelebration:
              destinationCelData.anythingElseAboutCeleberation,
            howSoonDoYouWantToBook: destinationCelData.howSoonDoYouWantToBook,
          },
        },
        {
          headers: {
            token: authToken
              ? `${JSON.parse(localStorage.getItem("data1"))}`
              : "",
          },
        }
      );
      //   console.log(response.message);
      setErrMsg(response?.data.message);
      console.log(response?.data);
      setIsLoading(false);
      navigate("/thanks");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        toast.error("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Field Input");
        toast.error("Missing Field Input");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        toast.error("Unauthorized");
        // toast("");
        console.log(errMsg);
        setIsLoading(false);
      } else if (err.response?.status === 500) {
        // toast("Check your Inputs, and input the correct info");
        toast.error("Check your Inputs, and input the correct infos");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed Due To An Error");
        toast.error("Failed Due To An Error");
        setIsLoading(false);
      }
    }
  };

  return (
    <form className="px-2" onSubmit={handleSubmit}>
      {Object.keys(errors).length !== 0 ? (
        <div className="errorMsgWrapper">
          {errors["setDateForCel"] && (
            <div className="errorMsg">{errors["setDateForCel"]}</div>
          )}
          {errors["daysToSpendCeleberating"] && (
            <div className="errorMsg">{errors["daysToSpendCeleberating"]}</div>
          )}
          {errors["howSoonDoYouWantToBook"] && (
            <div className="errorMsg">{errors["howSoonDoYouWantToBook"]}</div>
          )}
        </div>
      ) : null}
      <div className="mb-3 pt-3 email-container">
        <label className="m-1">
          How many days would you like to celebrate with your guests?
        </label>
        <input
          type="text"
          name="daysToSpendCeleberating"
          className="form-control email-container-input"
          defaultValue={destinationCelData.daysToSpendCeleberating}
          onChange={handleChangeDestinationCel}
        />
      </div>
      <div className="mb-3 pt-3 email-container">
        <label className="m-1">
          {/* If you have any dates set, or even just a month & year? */}
          If you have your dates set, or even just a month & year, please
          provide:
        </label>
        <input
          type="date"
          name="setDateForCel"
          className="form-control email-container-input"
          defaultValue={destinationCelData.setDateForCel}
          onChange={handleChangeDestinationCel}
        />
      </div>
      <div className="mb-3 pt-3 email-container d-flex flex-column justify-content-start align-items-start">
        <label className="mb-1">
          What TKE service(s) are you interested in?{" "}
        </label>
        <div className="d-flex gap-2 pt-1">
          <input
            type="checkbox"
            value="Logistics solutions for self and family"
            onChange={(e) => setwhatTKE1(e.target.value)}
          />
          <span>Logistics solutions for self and family</span>
        </div>
        <div className="d-flex gap-2 pt-3 ">
          <input
            type="checkbox"
            value="Logistics solutions for guests"
            onChange={(e) => setwhatTKE2(e.target.value)}
          />
          Logistics solutions for guests
        </div>
        <div className="d-flex gap-2 pt-3">
          <input
            type="checkbox"
            value="Curating activities/tours for guests"
            onChange={(e) => setwhatTKE3(e.target.value)}
          />
          Curating activities/tours for guests
        </div>
        <div className="d-flex gap-2 pt-3">
          <input
            className="omo1"
            type="checkbox"
            value="Planning and coordinating celebration party at destination"
            onChange={(e) => setwhatTKE4(e.target.value)}
          />
          Planning and coordinating celebration party at destination
        </div>
        <div className="d-flex gap-2 pt-3">
          <input
            type="checkbox"
            value="Physical presence of at least one TKE Concierge at destination"
            onChange={(e) => setwhatTKE5(e.target.value)}
          />
          Physical presence of at least one TKE Concierge at destination
        </div>
      </div>

      <div className="mb-3 pt-3 formArea-bespoke">
        <label
          // for="exampleFormControlTextarea1"
          className="form-label bespoke2-label"
        >
          Is there anything else we need to know about your celebration?
        </label>
        <textarea
          name="anythingElseAboutCeleberation"
          className="form-control formArea-bespoke-input"
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="type here"
          defaultValue={destinationCelData.anythingElseAboutCeleberation}
          onChange={handleChangeDestinationCel}
        ></textarea>
      </div>
      <div className="mb-3 pt-3 email-container">
        <label className="m-1">How soon are you looking to book? </label>
        <select
          className="form-select email-container-input3 "
          aria-label="Default select example"
          name="howSoonDoYouWantToBook"
          defaultValue={destinationCelData.howSoonDoYouWantToBook}
          onChange={handleChangeDestinationCel}
        >
          {/* <option selected>Select Option... </option> */}
          <option value="Ready to book">Ready to book</option>
          <option value="Within 30 days">Within 30 days</option>
          <option value="Research stage">Research stage</option>
        </select>
      </div>
      {/*  */}
      <div
        style={{ width: "100px", marginBottom: "-5.2rem", marginTop: "2.5rem" }}
        // className="mx-4"
      >
        <button
          style={{
            border: "none",
            background: "grey",
            color: "#fff",
            padding: "0.4rem 1rem",
          }}
          type="submit"
          className=""
          onClick={() => setCount(2)}
        >
          Previous
        </button>
      </div>
      <div id="space-btn" className="btn-container">
        <button type="submit" className="btn btn-primary">
          {isLoading ? "Loading..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default DestinationQuestionairePage2;
