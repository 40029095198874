import React, { useState, useEffect } from "react";
import "../Styles/UserHomePage.css";
// import Logo from "../../Images/TKE_logo.png";

import UserHeader from "../Components/UserHeader";
import UserJumbotron from "../Components/UserJumbotron";
import PlacesAdsBanner from "../Components/PlacesAdsBanner";
import TripList from "../Components/TripList";
// import UserFooters from "../Components/UserFooters";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import AuthBody from "../Components/AuthBody";
import axios from "../../Api/axios";

const localData2 = () => {
  const localData = JSON.parse(localStorage.getItem("emailTrip"));
  return localData ? localData : [];
};
const UserHomePage = () => {
  const { setMemberProfile, setTotalT, setNamedUser, reload } =
    useGlobalContext();
  const [singleUserTrips, setSingleUserTrips] = useState(localData2);
  const [singleUserBanner, setSingleUserBanner] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [member, setMember] = useState("");

  // console.log();

  const emailId = JSON.parse(localStorage.getItem("emailId"));
  const fetchTrips = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/trips/get-all?email=${emailId}`, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data1"))}`,
        },
      });
      const { data } = response?.data;
      setSingleUserTrips(data);
      setTotalT(data.length);
      setIsLoading(false);
      // console.log(topReferrals);
    } catch (err) {
      console.log(err.response);
    }
  };

  // useEffect(() => {
  //   fetchTrips();
  // }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/admin/users?email=${emailId}`, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data1"))}`,
        },
      });
      // const { data } = response?.data;
      localStorage.setItem(
        "userName",
        JSON.stringify(response?.data?.data?.firstName)
      );
      setSingleUserBanner(response?.data?.data?.banner?.link);
      setMember(response?.data?.data?.membership);
      setMemberProfile(response?.data?.data?.membership);

      setNamedUser(response?.data?.data?.firstName);
      // setNamedUser(response?.data?.data?.firstName);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchTrips();
  }, [reload, emailId]);

  useEffect(() => {
    fetchUsers();
  }, [emailId]);

  useEffect(() => {
    localStorage.setItem("emailTrip", JSON.stringify(singleUserTrips));
  }, [singleUserTrips]);

  return (
    <AuthBody>
      <UserJumbotron member={member} />
      <PlacesAdsBanner banner={singleUserBanner} />
      <TripList singleUserTrips={singleUserTrips} />
    </AuthBody>
  );
};

export default UserHomePage;
