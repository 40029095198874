import React, { useState } from "react";
// import { GoCalendar } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import axios from "../../Api/axios";
import { toast } from "react-toastify";
const BESPOKE_URL = "/questionnaires/create";

const BespokePage2 = ({ bespoke, setCount }) => {
  // checkbox value
  const [whatTKE11, setwhatTKE11] = useState("");
  const [whatTKE12, setwhatTKE12] = useState("");
  const [whatTKE13, setwhatTKE13] = useState("");
  const [whatTKE14, setwhatTKE14] = useState("");
  const [whatTKE15, setwhatTKE15] = useState("");
  const [whatTKE16, setwhatTKE16] = useState("");
  const [whatTKE17, setwhatTKE17] = useState("");
  const [whatTKE18, setwhatTKE18] = useState("");
  const [whatTKE19, setwhatTKE19] = useState("");
  const [whatTKE20, setwhatTKE20] = useState("");
  const [whatTKE21, setwhatTKE21] = useState("");
  const choiceActivityOptions = [
    whatTKE11,
    whatTKE12,
    whatTKE13,
    whatTKE14,
    whatTKE15,
    whatTKE16,
    whatTKE17,
    whatTKE18,
    whatTKE19,
    whatTKE20,
    whatTKE21,
  ];

  // console.log(choiceActivityOptions);

  const bespoke11 = bespoke;
  const navigate = useNavigate();
  const { bespokeData, handleChangeBespoke, errMsg, setErrMsg, userData1 } =
    useGlobalContext();

  const [errors, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const { travelForWhat, budgetForEntireTrip, bookingTime } = bespokeData;

    let errors = {};
    let formIsValid = true;

    if (!bookingTime) {
      formIsValid = false;
      errors["bookingTime"] = "*please, select how soon you want to book.";
    }
    if (!budgetForEntireTrip) {
      formIsValid = false;
      errors["budgetForEntireTrip"] = "*please, enter your budget.";
    }

    setError(errors);
    return formIsValid;
  };

  const authToken = JSON.parse(localStorage.getItem("data1"));
  // console.log(document.getElementById("myInput1").value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return window.scrollTo(0, 0);
    setIsLoading(true);
    try {
      const response = await axios.post(
        BESPOKE_URL,
        {
          user: {
            firstName: userData1.firstName,
            lastName: userData1.lastName,
            email: userData1.email,
            dialCode: userData1.dialCode ? userData1.dialCode : "234",
            phoneNumber: userData1.phoneNumber,
            gender: userData1.gender,
            age: userData1.age,
          },
          tripType: bespoke11 ? bespoke11 : userData1.tripType,
          tripDetails: {
            whereTo: bespokeData.whereTo,
            needVisa: bespokeData.needVisa,
            whatCity: bespokeData.whatCity,
            daysLikelyToSpend: bespokeData.daysLikelyToSpend,
            anyDateSet: bespokeData.anyDateSet,
            enterDateToTravel: bespokeData.enterDateToTravel,
            enterDateToArrive: bespokeData.enterDateToArrive,
            ideaMonthYear: bespokeData.ideaMonthYear,
            travelForWhat: bespokeData.travelForWhat,
            travelWithWho: bespokeData.travelWithWho,
            numberOfTravellerAdult: bespokeData.numberOfTravellerAdult,
            numberOfTravellerKids: bespokeData.numberOfTravellerKids,
            choiceActivityWhileTravelling: choiceActivityOptions,
            // choiceActivityWhileTravelling:
            //   bespokeData.choiceActivityWhileTravelling,
            budgetForEntireTrip: bespokeData.budgetForEntireTrip,
            aboutTrip: bespokeData.aboutTrip,
            bookingTime: bespokeData.bookingTime,
          },
        },
        {
          headers: {
            token: authToken
              ? `${JSON.parse(localStorage.getItem("data1"))}`
              : "",
          },
        }
      );
      setErrMsg(response?.data.message);
      // console.log(response?.data.msg);
      setIsLoading(false);
      navigate("/thanks");
      if (response?.data.msg) {
        toast(response?.data.msg);
        // document.getElementById("myInput1").value = "";
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        toast.error("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Field Input");
        toast.error("Missing Field Input");
        setIsLoading(false);
      } else if (err.response?.status === 500) {
        setErrMsg("Check your Inputs, and input the correct infos");
        toast.error("Check your Inputs, and input the correct infos");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        toast.error("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed Due To An Error");
        toast.error("Failed Due To An Error");
        setIsLoading(false);
      }
    }
  };

  return (
    <form id="bes2" className="px-2" onSubmit={handleSubmit}>
      {Object.keys(errors).length !== 0 ? (
        <div className="errorMsgWrapper">
          {errors["bookingTime"] && (
            <div className="errorMsg">{errors["bookingTime"]}</div>
          )}
          {errors["budgetForEntireTrip"] && (
            <div className="errorMsg">{errors["budgetForEntireTrip"]}</div>
          )}
        </div>
      ) : null}
      {/*  */}

      <div className="mb-3 pt-3 email-container">
        <label className="form-label bespoke2-label">
          What are you travelling for ?
        </label>
        <input
          id="myInput8"
          type="text"
          name="travelForWhat"
          className="form-control email-container-input"
          defaultValue={bespokeData.travelForWhat}
          onChange={handleChangeBespoke}
        />
      </div>

      <div className="mb-3 pt-3 email-container">
        <label className="form-label bespoke2-label">
          Who are you travelling with?
        </label>
        <input
          id="myInput9"
          type="text"
          name="travelWithWho"
          className="form-control email-container-input"
          defaultValue={bespokeData.travelWithWho}
          onChange={handleChangeBespoke}
        />
      </div>

      <div className="mb-3 pt-3 ">
        <label className="form-label bespoke2-label">
          How many people will be travelling in total?
        </label>
        <div className="d-flex How-many">
          <input
            id="myInput10"
            type="text"
            name="numberOfTravellerAdult"
            className="form-control adults"
            placeholder="Adults"
            defaultValue={bespokeData.numberOfTravellerAdult}
            onChange={handleChangeBespoke}
          />
          <input
            id="myInput11"
            type="text"
            name="numberOfTravellerKids"
            className="form-control children"
            placeholder="Children"
            defaultValue={bespokeData.numberOfTravellerKids}
            onChange={handleChangeBespoke}
          />
        </div>
      </div>
      <div
        id="check-b"
        className="mb-3 pt-3 email-container d-flex flex-column justify-content-start align-items-start"
      >
        <label className="mb-1">
          What would you like to do while travelling?{" "}
        </label>
        <div className="d-flex gap-2 pt-1">
          <input
            type="checkbox"
            value="Food & Beverages"
            onChange={(e) => setwhatTKE11(e.target.value)}
          />
          <span>Food & Beverages</span>
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Must See Attractions"
            onChange={(e) => setwhatTKE12(e.target.value)}
          />
          Must See Attractions
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Museum/Art"
            onChange={(e) => setwhatTKE13(e.target.value)}
          />
          Museum/Art
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Historical buildings"
            onChange={(e) => setwhatTKE14(e.target.value)}
          />
          Historical buildings
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Spa/Wellness"
            onChange={(e) => setwhatTKE15(e.target.value)}
          />
          Spa/Wellness
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Tours"
            onChange={(e) => setwhatTKE16(e.target.value)}
          />
          Tours
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Local culture"
            onChange={(e) => setwhatTKE17(e.target.value)}
          />
          Local culture
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Performance/Shows"
            onChange={(e) => setwhatTKE18(e.target.value)}
          />
          Performance/Shows
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Nightlife"
            onChange={(e) => setwhatTKE19(e.target.value)}
          />
          Nightlife
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Adventure sports"
            onChange={(e) => setwhatTKE20(e.target.value)}
          />
          Adventure sports
        </div>
        <div className="d-flex gap-2 pt-2">
          <input
            type="checkbox"
            value="Other"
            onChange={(e) => setwhatTKE21(e.target.value)}
          />
          Other
        </div>
      </div>
      {/* End of checkbox */}
      <div className="mb-3 pt-3">
        <label className="form-label bespoke2-label">
          What is your budget for your entire trip? Enter a range or single
          amount.
        </label>
        <div className="budget">
          <input
            id="myInput12"
            type="text"
            name="budgetForEntireTrip"
            className="form-control budget-input"
            aria-describedby="emailHelp"
            defaultValue={bespokeData.budgetForEntireTrip}
            onChange={handleChangeBespoke}
          />
        </div>
      </div>
      {/*  */}
      <div className="mb-3 pt-3 formArea-bespoke">
        <label className="form-label bespoke2-label">
          Is there anything else we need to know about your trip?
        </label>
        <textarea
          id="myInput13"
          name="aboutTrip"
          className="form-control formArea-bespoke-input"
          rows="3"
          placeholder="type here"
          defaultValue={bespokeData.aboutTrip}
          onChange={handleChangeBespoke}
        ></textarea>
      </div>
      {/*  */}

      {/*  */}
      <div className="mb-3 pt-3 email-container">
        <label className="form-label bespoke2-label">
          How soon are you looking to book?
        </label>
        <select
          className="form-select email-container-input3 "
          aria-label="Default select example"
          name="bookingTime"
          defaultValue={bespokeData.bookingTime}
          onChange={handleChangeBespoke}
        >
          <option selected>Select Option... </option>
          <option value="Ready to book">Ready to book</option>
          <option value="Within 30 days">Within 30 days</option>
          <option value="Research stage">Research stage</option>
        </select>
      </div>
      {/*  */}
      <div
        style={{ width: "100px", marginBottom: "-5.2rem", marginTop: "2.5rem" }}
        // className="mx-4"
      >
        <button
          style={{
            border: "none",
            background: "grey",
            color: "#fff",
            padding: "0.4rem 1rem",
          }}
          type="submit"
          className=""
          onClick={() => setCount(2)}
        >
          Previous
        </button>
      </div>
      <div id="space-btn" className="btn-container">
        <button type="submit" className="btn btn-primary">
          {isLoading ? "Loading" : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default BespokePage2;
