import React, { useState } from "react";
import Body from "../Components/Body";

// import "../Styles/omo1.css";
import { useParams } from "react-router-dom";
import ContentHead from "../Components/ContentHead";
import DestinationQuestionairePage1 from "../Components/DestinationQuestionairePage1";
import DestinationQuestionairePage2 from "../Components/DestinationQuestionairePage2";
import ProgressBar from "../Components/ProgressBar";
import Title from "../Components/Title";

const DestinationCelebQuestioniare = () => {
  const { celebration } = useParams();
  // console.log(celebration);
  const [count, setCount] = useState(2);

  return (
    <Body>
      <div>
        <Title title="DESTINATION CELEBRATION QUESTIONNAIRE" />
        <ContentHead />
        <ProgressBar count={count} />
        {count === 2 && <DestinationQuestionairePage1 setCount={setCount} />}
        {count === 3 && (
          <DestinationQuestionairePage2
            celebration11={celebration}
            setCount={setCount}
          />
        )}
      </div>
    </Body>
  );
};

export default DestinationCelebQuestioniare;
