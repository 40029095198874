export const userData = {
  firstName: "",
  lastName: "",
  email: "",
  dialCode: "",
  phoneNumber: "",
  gender: "",
  age: "",
  tripType: "",
};

export const bespoke1 = {
  //

  whereTo: "",
  needVisa: "",
  whatCity: "",
  daysLikelyToSpend: "",
  anyDateSet: "",
  enterDateToTravel: "",
  enterDateToArrive: "",
  ideaMonthYear: "",
  travelForWhat: "",
  travelWithWho: "",
  numberOfTravellerAdult: "",
  numberOfTravellerKids: "",
  choiceActivityWhileTravelling: ["", ""],
  budgetForEntireTrip: "",
  aboutTrip: "",
  bookingTime: "",
};

export const destinationCelData1 = {
  occationYouAreCeleberating: "",
  likeToCeleberteWhat: "",
  estimateGuestToCelerateWith: "",
  AdultsCeleberatingWith: "",
  childrenCeleberatingWith: "",
  whatCityYouMostLikelyExpectQuests: "",
  daysToSpendCeleberating: "",
  setDateForCel: "",
  whatTKEServiceDoYouWant: "",
  anythingElseAboutCeleberation: "",
  howSoonDoYouWantToBook: "",
};
