import "./App.css";
// import MultiPage from "./noAuthArea/Pages/MultiPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./noAuthArea/Pages/Login";

import TravelQuestionaire from "./noAuthArea/Pages/TravelQuestionaire";
import DestinationCelebQuestioniare from "./noAuthArea/Pages/DestinationCelebQuestioniare";
import BespokeQuestionaire from "./noAuthArea/Pages/BespokeQuestionaire";
import ForgotPassword from "./noAuthArea/Pages/ForgotPassword";
import ResetPassword from "./noAuthArea/Pages/ResetPassword";
import ThankYou from "./noAuthArea/Pages/ThankYou";
import UserHomePage from "./authArea/Pages/UserHomePage";
import UserTripPage from "./authArea/Pages/UserTripPage";
import ProfileSetupPage from "./authArea/Pages/ProfileSetupPage";
import RequireAuth from "./RequireAuth/RequireAuth";
import { Layout } from "./Layout";
import ChangePasswordPage from "./authArea/Pages/ChangePasswordPage";
import { Error } from "./noAuthArea/Pages/Error";
import Example from "./Example";

function App() {
  return (
    <BrowserRouter>
      <div className="AppWrap">
        <Routes>
          <Route path="/travels" element={<TravelQuestionaire />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/example" element={<Example />} />
          <Route
            path="destination/:celebration"
            element={<DestinationCelebQuestioniare />}
          />
          <Route path="travel/:bespoke" element={<BespokeQuestionaire />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<UserHomePage />} />
              <Route path="/profile/settings" element={<ProfileSetupPage />} />
              <Route path="/change-password" element={<ChangePasswordPage />} />
              <Route path="/user/trip/:id" element={<UserTripPage />} />
            </Route>
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
