import React from "react";
import "../Styles/Itenary.css";

const Itenary = ({ trip }) => {
  // console.log(trip);
  return (
    <div className="container itenary-container">
      <h2 className="h22">Itinerary</h2>
      <section className="itenary-section">
        <div className="row itenary__container-1" style={{ padding: "1rem" }}>
          <div dangerouslySetInnerHTML={{ __html: trip }} />
        </div>
      </section>
    </div>
  );
};

export default Itenary;
