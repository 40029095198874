import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import "../Styles/errorMsg.css";

const DestinationQuestionairePage1 = ({ setCount }) => {
  const navigate = useNavigate();
  const { destinationCelData, handleChangeDestinationCel } = useGlobalContext();
  const [errors, setError] = useState({});

  const validateForm = () => {
    const {
      occationYouAreCeleberating,
      likeToCeleberteWhat,
      estimateGuestToCelerateWith,
    } = destinationCelData;

    let errors = {};
    let formIsValid = true;

    if (!occationYouAreCeleberating) {
      formIsValid = false;
      errors["occationYouAreCeleberating"] = "*Please enter Occassion.";
    }
    if (!likeToCeleberteWhat) {
      formIsValid = false;
      errors["likeToCeleberteWhat"] = "*Please enter celebration location.";
    }
    if (!estimateGuestToCelerateWith) {
      formIsValid = false;
      errors["estimateGuestToCelerateWith"] =
        "*Please enter estimated number of guest*s*.";
    }
    // if (!whatCity) {
    //   formIsValid = false;
    //   errors["whatCity"] = "*Please enter city.";
    // }

    setError(errors);
    return formIsValid;
  };

  const fireNextStep = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setCount(3);
    }
    return window.scrollTo(0, 0);
  };
  const authToken = JSON.parse(localStorage.getItem("data1"));
  const prevStep = (e) => {
    e.preventDefault();
    if (authToken) {
      navigate("/");
    } else {
      navigate("/travels");
    }
    return window.scrollTo(0, 0);
  };

  return (
    <form className="px-2">
      {Object.keys(errors).length !== 0 ? (
        <div className="errorMsgWrapper">
          {errors["occationYouAreCeleberating"] && (
            <div className="errorMsg">
              {errors["occationYouAreCeleberating"]}
            </div>
          )}
          {errors["likeToCeleberteWhat"] && (
            <div className="errorMsg">{errors["likeToCeleberteWhat"]}</div>
          )}
          {errors["estimateGuestToCelerateWith"] && (
            <div className="errorMsg">
              {errors["estimateGuestToCelerateWith"]}
            </div>
          )}
        </div>
      ) : null}
      <div className="mb-3 pt-3 email-container">
        <label className="m-1">What occasion are you celebrating?</label>
        <input
          type="text"
          name="occationYouAreCeleberating"
          className="form-control email-container-input"
          placeholder="Include age for birthdays and years for anniversaries"
          defaultValue={destinationCelData.occationYouAreCeleberating}
          onChange={handleChangeDestinationCel}
        />
      </div>
      <div className="mb-3 pt-3 email-container">
        <label className="m-1">Where would you like to celebrate?</label>
        <input
          type="text"
          name="likeToCeleberteWhat"
          className="form-control email-container-input"
          placeholder="You can enter multiple destinations if undecided"
          defaultValue={destinationCelData.likeToCeleberteWhat}
          onChange={handleChangeDestinationCel}
        />
      </div>
      <div className="mb-3 pt-3 email-container">
        <label className="m-1">How many guests are you expecting ?</label>
        <input
          type="text"
          name="estimateGuestToCelerateWith"
          className="form-control email-container-input"
          placeholder="You can enter an estimate or a range"
          defaultValue={destinationCelData.estimateGuestToCelerateWith}
          onChange={handleChangeDestinationCel}
        />
      </div>
      {/*  */}
      <div className="mb-3 pt-3 ">
        <label className="m-1">Number of:</label>
        <div className="d-flex How-many">
          <input
            type="text"
            name="AdultsCeleberatingWith"
            className="form-control adults"
            aria-describedby="emailHelp"
            placeholder="Adults"
            defaultValue={destinationCelData.AdultsCeleberatingWith}
            onChange={handleChangeDestinationCel}
          />
          <input
            type="text"
            name="childrenCeleberatingWith"
            className="form-control children"
            placeholder="Children"
            defaultValue={destinationCelData.childrenCeleberatingWith}
            onChange={handleChangeDestinationCel}
          />
        </div>
      </div>
      {/*  */}
      <div className="mb-3 pt-3 email-container">
        <label className="m-1">
          What city would majority of your guests be travelling from?
        </label>
        <input
          type="text"
          name="whatCityYouMostLikelyExpectQuests"
          className="form-control email-container-input"
          // placeholder="What city would majority of your guests be travelling from ?"
          defaultValue={destinationCelData.whatCityYouMostLikelyExpectQuests}
          onChange={handleChangeDestinationCel}
        />
      </div>
      {/*  */}
      <div
        style={{ width: "100px", marginBottom: "-5.2rem", marginTop: "2.5rem" }}
        // className="mx-4"
      >
        <button
          style={{
            border: "none",
            background: "grey",
            color: "#fff",
            padding: "0.4rem 1rem",
          }}
          type="submit"
          className=""
          onClick={prevStep}
        >
          {authToken ? "Home" : "Previous"}
        </button>
      </div>
      <div id="space-btn" className="btn-container">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={fireNextStep}
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default DestinationQuestionairePage1;
