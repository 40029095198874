import React from "react";
import DocView from "../../Images/UserProfileImg/docViewArrow.png";
import "../Styles/Payment.css";

const Payment = ({ trip }) => {
  // console.log(trip);
  const newPaid =
    trip?.filter((item) => {
      return item?.status === "Paid";
    }) || [];
  const newNotPaid =
    trip?.filter((item) => {
      return item?.status === "Not Paid";
    }) || [];

  const trip1 = [...newNotPaid, ...newPaid];

  return (
    <div className="container agreement-container2">
      <h2>Payment</h2>
      <section className="section-agreement2">
        {!trip ? (
          <p style={{ margin: "6rem", color: "black" }}>Loading...</p>
        ) : (
          trip1?.map((item, index) => {
            return (
              <div key={index} className="row my-3">
                <div className="agreement-row-wrap2">
                  <span className="col-doc2">{item?.title}</span>
                  <span
                    className={
                      item?.status.toLowerCase() === "not paid"
                        ? "col-sign2"
                        : "paid"
                    }
                  >
                    {item?.status.toLowerCase()}
                  </span>
                  <span className="col-amount">{item?.amount}</span>
                  {item?.status === "Not Paid" ? (
                    <div className="agreement-invoice">
                      <a
                        style={{ textDecoration: "none" }}
                        // href={item?.pay_now_link}
                        href={
                          item?.pay_now_link?.includes("https://")
                            ? item?.pay_now_link
                            : "https://" + item?.pay_now_link
                        }
                        className="payNow"
                        target="_blank"
                      >
                        Pay Now
                      </a>
                      <a
                        style={{ textDecoration: "none" }}
                        // href={item?.invoice_link}
                        href={
                          item?.invoice_link?.includes("https://")
                            ? item?.invoice_link
                            : "https://" + item?.invoice_link
                        }
                        className="invoiceV"
                        target="_blank"
                      >
                        View Invoice
                      </a>
                    </div>
                  ) : (
                    <div className="agreement-docs2">
                      <a
                        style={{ textDecoration: "none" }}
                        // href={item?.reciept_link}
                        href={
                          item?.reciept_link?.includes("https://")
                            ? item?.reciept_link
                            : "https://" + item?.reciept_link
                        }
                        className="col-doc-view2"
                        target="_blank"
                      >
                        View Receipt
                      </a>
                      <img src={DocView} alt="" />
                    </div>
                  )}
                </div>
              </div>
            );
          })
        )}
        {/* <div className="row my-3">
          <div className="agreement-row-wrap2">
            <span className="col-doc2">Legal Document 2</span>
            <span className="col-sign2 paid">Paid</span>
            <span className="col-amount">$1,500</span>
            <div className="agreement-docs2">
              <a
                style={{ textDecoration: "none" }}
                href=""
                className="col-doc-view2"
              >
                View Recipt
              </a>
              <img src={DocView} alt="" />
            </div>
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default Payment;

{
  /* <div className="agreement-docs2">
  <a style={{ textDecoration: "none" }} href="" className="col-doc-view2">
    View Recipt
  </a>
  <img src={DocView} alt="" />
</div>; */
}
