import React from "react";
import "../Styles/ProgressBar.css";

const ProgressBar = ({ count }) => {
  return (
    <div className="progressContainer px-2 mt-3 mb-5">
      <span>{count} of 3 Completed</span>
      <div className="progress">
        <span
          style={{
            width: count === 1 ? "33.333%" : count === 2 ? "66.66%" : "100%",
            background: "#A78A48",
          }}
        ></span>
      </div>
    </div>
  );
};

export default ProgressBar;
