import React from "react";
import { Link } from "react-router-dom";
import Body from "../Components/Body";

export const Error = () => {
  return (
    <Body>
      <div
        style={{
          textAlign: "center",
          fontSize: "1.2rem",
          margin: "40% 0 0 0",
        }}
        className=""
      >
        <p style={{ marginBottom: "50px" }}>404: Page Not Found</p>

        <Link to="/">GO HOME</Link>
      </div>
    </Body>
  );
};

{
  /* <Link to='/'>GO HOME</Link> */
}
