import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const location = useLocation();

  const authToken = JSON.parse(localStorage.getItem("data1"));

  return authToken ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
  //   if (!authToken) {
  //     return <Navigate to="/login" replace />;
  //   }
  //   return children;
};

export default RequireAuth;
