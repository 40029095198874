// import axios from "axios";
import { toast } from "react-toastify";
import React, { useState } from "react";
import Body from "../Components/Body";
import Title from "../Components/Title";
import { useNavigate } from "react-router-dom";
import axios from "../../Api/axios";

const RESET_PASSWORD = "/user/reset-password";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    resetCode: "",
    password: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, resetCode } = values;
    if (password === "" || resetCode === "") {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.patch(
        RESET_PASSWORD,
        { resetCode: resetCode, password: password },
        {
          headers: {
            token: `${JSON.parse(localStorage.getItem("reset100"))}`,
          },
        }
      );
      console.log(response?.data);
      setErrMsg(response?.data.message);
      if (response?.data.msg) {
        toast(response?.data.msg);
        console.log(response?.data.msg);
      }

      setIsLoading(false);
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 409) {
        setErrMsg("Wrong Taken");
        toast(err.response?.data.msg);
        toast(err.response?.data.message);
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("Wrong Taken");
        toast(err.response?.data.msg);
        toast(err.response?.data.message);
        setIsLoading(false);
      } else {
        setErrMsg("Registration Failed");
        setIsLoading(false);
      }
    }
  };

  return (
    <Body>
      <div className="login-container p-4">
        <Title title="Reset Password" />
        <form className="px-2 login-container-form" onSubmit={handleSubmit}>
          <div class="mb-3 pt-3 login-input-wrapper">
            <input
              type="text"
              name="resetCode"
              class="form-control"
              id="exampleInputEmail"
              placeholder="Reset Code"
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 pt-3 login-input-wrapper">
            <input
              type="password"
              name="password"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              onChange={handleChange}
            />
          </div>

          <span
            style={{
              color: "green",
            }}
          >
            A password reset code has been sent to your email.
          </span>
          <div class="btn-container-login">
            <button type="submit" class="btn btn-primary">
              {isLoading ? "Resetting" : "Set Password"}
            </button>
          </div>
        </form>
      </div>
    </Body>
  );
};

export default ResetPassword;
