import React from "react";
import Body from "../../noAuthArea/Components/Body";
import "../Styles/AuthBody.css";
import UserFooters from "./UserFooters";
import UserHeader from "./UserHeader";

const AuthBody = ({ children }) => {
  return (
    <div className="user-home-container container">
      <UserHeader />
      {children}
      <UserFooters />
    </div>
  );
};

export default AuthBody;
