import React from "react";
import { Link } from "react-router-dom";
import Body from "../Components/Body";
import Title from "../Components/Title";

const ResetPassword = () => {
  const styled = {
    border: "none",
    outline: "none",
    background: "#A78A48",
    width: "170px",
    height: "44px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "700",
    fontSize: "20px,",
    lineHeight: "24px",
    TextDecoration: "none",
  };

  const authToken = JSON.parse(localStorage.getItem("data1"));

  return (
    <Body>
      <div className="login-container p-5 d-flex justify-content-center align-items-center flex-column">
        <Title title="Thank you" />
        <p style={{ textAlign: "center" }} className="my-4">
          We've received your travel request and we'll get back to you as soon
          as possible!
        </p>
        {authToken ? (
          <Link
            // href="https://thekeona.com/"
            to="/"
            style={styled}
            className="my-4"
            onClick={(e) => {
              e.target.reset();
            }}
          >
            Done
          </Link>
        ) : (
          <a
            href="https://thekeona.com/"
            style={styled}
            className="my-4"
            onClick={(e) => {
              e.target.reset();
            }}
          >
            Done
          </a>
        )}
      </div>
    </Body>
  );
};

export default ResetPassword;
