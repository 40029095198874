import React from "react";
import "../Styles/PlacesAdsBanner.css";
import PlacesBanner from "../../Images/placesBanner.png";

const PlacesAdsBanner = ({ banner }) => {
  // const banner = JSON.parse(localStorage.getItem("banner"));
  // console.log(banner);
  return (
    <div className="places-banner">
      <img src={banner ? banner : PlacesBanner} alt="" />
    </div>
  );
};

export default PlacesAdsBanner;
