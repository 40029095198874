import React, { useState } from "react";
import { GoCalendar } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import "../Styles/errorMsg.css";

const BespokePage1 = ({ setCount }) => {
  const navigate = useNavigate();
  const { bespokeData, handleChangeBespoke } = useGlobalContext();
  const [errors, setError] = useState({});

  const validateForm = () => {
    const { whereTo, whatCity } = bespokeData;

    let errors = {};
    let formIsValid = true;

    if (!whereTo) {
      formIsValid = false;
      errors["whereTo"] = "*Please enter where you would like to go.";
    }
    if (!whatCity) {
      formIsValid = false;
      errors["whatCity"] =
        "*Please enter what city would you be travelling from?.";
    }

    setError(errors);
    return formIsValid;
  };

  const fireNextStep = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setCount(3);
    }
    return window.scrollTo(0, 0);
  };

  const authToken = JSON.parse(localStorage.getItem("data1"));
  const prevStep = (e) => {
    e.preventDefault();
    if (authToken) {
      navigate("/");
    } else {
      navigate("/travels");
    }
    return window.scrollTo(0, 0);
  };

  // console.log(errors);

  return (
    <form id="bes1" className="px-2">
      {Object.keys(errors).length !== 0 ? (
        <div className="errorMsgWrapper">
          {errors["whereTo"] && (
            <div className="errorMsg">{errors["whereTo"]}</div>
          )}
          {errors["whatCity"] && (
            <div className="errorMsg">{errors["whatCity"]}</div>
          )}
        </div>
      ) : null}
      <div className="mb-3 pt-3 email-container">
        <label className="form-check-label bespoke-label py-2">
          Where would you like to go?
        </label>
        <input
          id="myInput1"
          type="text"
          name="whereTo"
          className="form-control email-container-input"
          // placeholder=" Where would you like to go"
          defaultValue={bespokeData.whereTo}
          onChange={handleChangeBespoke}
        />
      </div>
      <div className="mb-2 pt-2 howdy">
        <label className="form-check-label bespoke-label py-2">
          Would you require a visa to the destination?
        </label>
        <div className="d-flex radio-flex">
          <div className="form-check">
            <input
              className="form-check-input bespoke-radio"
              type="radio"
              name="needVisa"
              value="yes"
              checked={bespokeData.needVisa === "yes"}
              onChange={handleChangeBespoke}
            />
            <label
              className="form-check-label bespoke-label"
              // for="flexRadioDefault1"
            >
              Yes
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input bespoke-radio"
              type="radio"
              name="needVisa"
              value="no"
              checked={bespokeData.needVisa === "no"}
              onChange={handleChangeBespoke}
            />
            <label className="form-check-label bespoke-label">No</label>
          </div>
        </div>
      </div>
      <div className="mb-3 pt-3 email-container">
        <label className="form-check-label bespoke-label py-2">
          What city would you be travelling from?
        </label>
        <input
          id="myInput2"
          type="text"
          name="whatCity"
          className="form-control email-container-input"
          // placeholder="What city would you be travelling from?"
          defaultValue={bespokeData.whatCity}
          onChange={handleChangeBespoke}
        />
      </div>
      <div className="mb-3 pt-3 email-container">
        <label className="form-check-label bespoke-label py-2">
          How many days would you like to travel for?
        </label>
        <input
          id="myInput3"
          type="text"
          name="daysLikelyToSpend"
          className="form-control email-container-input"
          // placeholder="How many days would you like to travel for ?"
          defaultValue={bespokeData.daysLikelyToSpend}
          onChange={handleChangeBespoke}
        />
      </div>
      <div className="mb-3 pt-2 howdy">
        <label className="form-check-label bespoke-label py-2">
          Do you have your dates set?
        </label>
        <div className="d-flex radio-flex">
          <div className="form-check">
            <input
              id="myInput4"
              className="form-check-input bespoke-radio"
              type="radio"
              name="anyDateSet"
              value="yes"
              checked={bespokeData.anyDateSet === "yes"}
              onChange={handleChangeBespoke}
            />
            <label className="form-check-label bespoke-label">Yes</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input bespoke-radio"
              type="radio"
              name="anyDateSet"
              value="no"
              checked={bespokeData.anyDateSet === "no"}
              onChange={handleChangeBespoke}
            />
            <label className="form-check-label bespoke-label">No</label>
          </div>
        </div>
      </div>
      {bespokeData.anyDateSet === "yes" ? (
        <div className="mb-3">
          <label className="form-check-label bespoke-label py-2">
            If yes, enter your date below.
          </label>
          <div className="d-flex align-items-center dated">
            <div className="d-flex align-items-center one">
              <GoCalendar className="calender" />
              <input
                id="myInput5"
                style={{ border: "none" }}
                type="date"
                name="enterDateToTravel"
                className="form-control"
                aria-describedby="emailHelp"
                // defaultValue={bespokeData.enterDateToTravel}
                onChange={handleChangeBespoke}
              />
            </div>
            <span className="andTo">To</span>
            <div className="d-flex align-items-center one">
              <GoCalendar className="calender" />
              <input
                id="myInput6"
                style={{ border: "none" }}
                type="date"
                name="enterDateToArrive"
                className="form-control"
                // defaultValue={bespokeData.enterDateToArrive}
                onChange={handleChangeBespoke}
              />
            </div>
          </div>
        </div>
      ) : bespokeData.anyDateSet === "no" ? (
        <div className="mb-3">
          <label
            className="form-check-label bespoke-label py-2"
            // for="flexRadioDefault2"
          >
            If not, do you have an idea of the month/year you’d like to travel
          </label>
          <div className="d-flex align-items-center two">
            <GoCalendar className="calender" />
            <input
              id="myInput7"
              type="text"
              name="ideaMonthYear"
              className="form-control Bespoke-date-1"
              // defaultValue={bespokeData.ideaMonthYear}
              // placeholder="MM/YY"
              onChange={handleChangeBespoke}
            />
          </div>
        </div>
      ) : null}
      <div
        style={{ width: "100px", marginBottom: "-5.2rem", marginTop: "2.5rem" }}
        // className="mx-4"
      >
        <button
          style={{
            border: "none",
            background: "grey",
            color: "#fff",
            padding: "0.4rem 1rem",
          }}
          type="submit"
          className=""
          onClick={prevStep}
        >
          {authToken ? "Home" : "Previous"}
        </button>
      </div>
      <div id="space-btn" className="btn-container ">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={fireNextStep}
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default BespokePage1;
