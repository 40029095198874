import React, { useState } from "react";
import AuthBody from "../Components/AuthBody";
import "../Styles/ChangePasswordPage.css";
import axios from "../../Api/axios";
import { ToastContainer, toast } from "react-toastify";
const CHANGE_PASSWORD = "/user/change-password";

const ChangePasswordPage = () => {
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    oldPassword: "",
  });

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, oldPassword } = values;
    if (password === "" || oldPassword === "") {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        CHANGE_PASSWORD,
        { password, oldPassword },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data1"))}`,
          },
        }
      );
      console.log(response);
      setErrMsg(response?.data.message);
      if (response?.data.msg) {
        toast(response?.data.msg);
        console.log(response?.data.msg);
      }
      setIsLoading(false);
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 409) {
        toast.error("Failed!, pls. check credentials");
        setErrMsg("failed");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        toast.error("old password is incorrect!!");
        setErrMsg("old password is incorrect!!");
        setIsLoading(false);
      } else {
        toast.error("Failed!, pls. check credentials");
        setErrMsg("Failed!, pls. check credentials");
        setIsLoading(false);
      }
    }
  };

  // console.log(values);

  return (
    <AuthBody>
      <div className="change-password">
        <h2>Change Password</h2>
        <form className="form-inline py-3 form-ik" onSubmit={handleSubmit}>
          <input
            type="password"
            name="password"
            className="form-control formit-child"
            placeholder="New Password"
            onChange={onChange}
          />
          <input
            type="password"
            name="oldPassword"
            className="form-control formit-child"
            placeholder="Old Password"
            onChange={onChange}
          />
          <button type="submit" className="btn passWordBTN">
            {isLoading ? "loading" : "Submit"}
          </button>
        </form>
      </div>
    </AuthBody>
  );
};

export default ChangePasswordPage;
