import React, { useState } from "react";
import "../Styles/TripReview.css";
import Upload from "../../Images/QuestionaireImg/Upload.png";
import { FiTrash2 } from "react-icons/fi";
import axios from "../../Api/axios";
import { toast } from "react-toastify";
import DocView from "../../Images/UserProfileImg/docViewArrow.png";
const DOC_UPLOAD = "/trips/additional-documents/submit";

const UploadDocuments = ({ _id, trip, trip2, setReLoad }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");

  const tt = image?.size > 200000;
  console.log();

  const imgSizing = () => {
    if (image?.size > 2000000) {
      return toast.error("file size is too large, image should not exceed 2mb");
    }
  };

  const validateForm = () => {
    let formIsValid = true;

    if (!title) {
      formIsValid = false;
    }
    if (!image) {
      formIsValid = false;
    }

    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return toast.error("title and image field most be filled");
    }
    if (imgSizing()) {
      return;
    }

    const formData = new FormData();
    formData.append("_id", _id);
    formData.append("document", image);
    formData.append("title", title);

    // console.log([...formData]);
    setIsLoading(true);
    try {
      const response = await axios.post(DOC_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${JSON.parse(localStorage.getItem("data1"))}`,
        },
      });
      setIsLoading(false);
      console.log(response?.data);
      // setSucces2((prev) => !prev);
      if (response?.data.msg) {
        toast("Successfully Sent!");
        console.log(response?.data.msg);
        setReLoad((prev) => !prev);
        event.target.reset();
      }
    } catch (error) {
      if (!error?.response) {
        toast.error("No Server Response");
        setIsLoading(false);
      } else if (error.response?.status === 500) {
        // setErrMsg("");
        toast.error("File too large, image should not be more than 2mb");
        setIsLoading(false);
      }
    }
  };

  const handleImage = (event) => {
    setImage(event.target.files[0]);
  };

  // const deleteDocs = async () => {
  //   e.preventDefault();
  // try {
  //   const response = await axios.post(
  //     CHANGE_PASSWORD,
  //     {
  //       _id: id,
  //       document_id: document_id,
  //     },
  //     {
  //       headers: {
  //         token: `${JSON.parse(localStorage.getItem("data1"))}`,
  //       },
  //     }
  //   );
  //   console.log(response);
  //   setErrMsg(response?.data.message);
  //   if (response?.data.msg) {
  //     toast(response?.data.msg);
  //     console.log(response?.data.msg);
  //   }
  //   setIsLoading(false);
  // } catch (err) {
  //   if (!err?.response) {
  //     setErrMsg("No Server Response");
  //     setIsLoading(false);
  //   } else {
  //     setErrMsg("Registration Failed");
  //     setIsLoading(false);
  //   }
  // }
  // };

  return (
    <div className="container trip-review-container">
      <h2>Upload Documents</h2>
      {trip2 && (
        <div
          className="instruction__div"
          style={{ margin: "0.7rem 0" }}
          dangerouslySetInnerHTML={{ __html: trip2 }}
        />
      )}
      <section className="section-trip-review">
        <form className="form-row my-4" onSubmit={handleSubmit}>
          <div className="my-4">
            {/* <label forhtmlFor="inputTag" className="">
              Title
            </label> */}

            <input
              // id="inputTag"
              type="text"
              className="input-1"
              placeholder="Input Title"
              onChange={(e) => setTitle(e.target.value)}
            />
            <span id="imageName"></span>
          </div>
          <div className="upload-wrap mt-3 mb-5">
            <label forhtmlfor="inputTag" className="upload-label">
              <img src={Upload} alt="" />
              upload File
              <input
                id="inputTag"
                type="file"
                className="upload-input"
                onChange={handleImage}
              />
              <span id="imageName"></span>
            </label>
            <span>{image?.name}</span>
          </div>
          <div className="btn-container justify-content-start">
            <button type="submit" className="btn btn-primary">
              {isLoading ? "sending..." : "submit"}
            </button>
          </div>
        </form>
      </section>
      <hr className="my-5" />
      <section className="section-trip-review">
        {trip?.map((item) => {
          return (
            <div key={item?._id} className="row my-3">
              <DocList item={item} _id={_id} setReLoad={setReLoad} />
            </div>
          );
        })}
      </section>
    </div>
  );
};

const DocList = ({ item, _id, setReLoad }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();

    // const token = `${JSON.parse(localStorage.getItem("data1"))}`;
    const data = {
      document_id: item?._id,
      _id: _id,
    };
    // console.log(data);

    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://tke-travel.onrender.com/api/v1/trips/additional-documents/delete",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data1"))}`,
          },
        }
      );
      if (response?.data?.msg) {
        toast(response?.data?.msg);
      }
      setIsLoading(false);
      setReLoad((prev) => !prev);
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
        setIsLoading(false);
      } else {
        toast.error("Request Does not Exist");
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="agreement-row-wrap22">
      <span className="col-doc22">{item?.title}</span>
      <div className="agreement-docs22">
        <a
          href={item?.document?.link}
          target="_blank"
          className="col-doc-view22"
        >
          View{" "}
        </a>
        {/* <img src={DocView} alt="" /> */}
        <FiTrash2 style={{ color: "orangered" }} onClick={handleDelete} />
      </div>
    </div>
  );
};

export default UploadDocuments;
