import React from "react";
import "../Styles/UserFooter.css";

const UserFooters = () => {
  return (
    <div className="container footer-container">
      <span>© 2022 All right reserved by The Keona Experience</span>
    </div>
  );
};

export default UserFooters;
