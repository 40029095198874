import React from "react";
import DocView from "../../Images/UserProfileImg/docViewArrow.png";
import "../Styles/Visa.css";

const Visa = ({ trip }) => {
  // console.log(trip);
  return (
    <div className="container agreement-container22">
      <h2>Visa</h2>
      <section className="section-agreement22 my-4">
        <p className="visa-p">{trip?.body}</p>
      </section>
      <section className="section-agreement22">
        {!trip ? (
          <p style={{ margin: "6rem", color: "black" }}>Loading...</p>
        ) : (
          trip?.map((item, index) => {
            console.log(item);
            return (
              <div key={item?._id} className="row my-3">
                <div className="agreement-row-wrap22">
                  <span className="col-doc22">{item?.title}</span>
                  <div className="agreement-docs22">
                    <a
                      // href={item?.document?.link}
                      href={
                        item?.document_link?.includes("https://")
                          ? item?.document_link
                          : "https://" + item?.document_link
                      }
                      target="_blank"
                      className="col-doc-view22"
                    >
                      View{" "}
                    </a>
                    <img src={DocView} alt="" />
                  </div>
                </div>
              </div>
            );
          })
        )}
        {/* <div className="row my-3">
          <div className="agreement-row-wrap22">
            <span className="col-doc22">Document Title 1</span>
            <div className="agreement-docs22">
              <span className="col-doc-view22">View </span>
              <img src={DocView} alt="" />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="agreement-row-wrap22">
            <span className="col-doc22">Document Title 1</span>
            <div className="agreement-docs22">
              <span className="col-doc-view22">View </span>
              <img src={DocView} alt="" />
            </div>
          </div>
        </div> */}
      </section>
    </div>
  );
};
export default Visa;
