import React, { useState } from "react";
import AuthBody from "../Components/AuthBody";
import "../Styles/ProfileSetupPage.css";
import { toast } from "react-toastify";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import SetupPage1 from "../../Images/QuestionaireImg/setupPage1.png";
import SetupPage2 from "../../Images/QuestionaireImg/setupPage2.png";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import axios from "../../Api/axios";
import { countries } from "./CountryData";
const CHANGE_PROFILE = "/user/change-details";

const ProfileSetupPage = () => {
  const { setReload, namedUser, memberProfile } = useGlobalContext();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    gender: "",
    email: "",
    address: "",
    city: "",
    country: "",
  });

  console.log(memberProfile);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      birthday,
      gender,
      email,
      address,
      city,
      country,
    } = values;
    if (firstName === "" || lastName === "" || email === "") {
      toast("fields required");
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        CHANGE_PROFILE,
        {
          firstName,
          lastName,
          birthday,
          gender,
          email,
          address,
          city,
          country,
        },
        {
          headers: {
            token: `${JSON.parse(localStorage.getItem("data1"))}`,
          },
        }
      );
      setErrMsg(response?.data.message);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setReload(true);
        console.log(response?.data.msg);
      }
      setIsLoading(false);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
        setIsLoading(false);
      } else {
        setErrMsg("Registration Failed");
        setIsLoading(false);
      }
    }
  };

  const name1 = JSON.parse(localStorage.getItem("userName"));

  // const member = JSON.parse(localStorage.getItem("member"));
  // const firstName1 = JSON.parse(localStorage.getItem("firstName1"));

  return (
    <AuthBody>
      <div className="settings-container">
        <Link to={"/"} className="back-dashboard">
          <FiChevronLeft className="back-dashboard-icon" />
          <span>Go to Dashboard</span>
        </Link>
        <section className="profile-wrap">
          <h3>Settings</h3>
          <div className="profile-row">
            <img src={SetupPage2} alt="" className="profile-row-img" />
            <div className="profile-row-div">
              <span style={{ textTransform: "capitalize" }}>
                {namedUser ? namedUser : name1}
              </span>
              <div className="profile-row-inner-div">
                {!memberProfile?.membershipType == "" && (
                  <div>
                    <img src={SetupPage1} alt="" />
                  </div>
                )}
                {memberProfile?.membershipType && (
                  <span>{memberProfile?.membershipType}</span>
                )}
              </div>
            </div>
          </div>
          <form className="settings-form my-5" onSubmit={handleSubmit}>
            <div className="row my-3">
              <div className="form-group col-sm">
                <label className="profile-label">First name</label>
                <input
                  type="text"
                  className="form-control profile-input"
                  name="firstName"
                  onChange={handleChange}
                  required
                  // placeholder="name@example.com"
                />
              </div>
              <div class="form-group col-sm">
                <label className="profile-label">Last Name</label>
                <input
                  type="text"
                  className="form-control profile-input"
                  name="lastName"
                  required
                  // placeholder="name@example.com"
                  onChange={handleChange}
                />
              </div>
            </div>
            {/*  */}
            <div className="row my-3">
              <div class="form-group col-sm">
                <label className="profile-label">Birthday</label>
                <input
                  style={{ border: "1px solid #ced4da" }}
                  type="date"
                  className="form-control profile-input"
                  name="Date Of Birth"
                  onChange={handleChange}
                  // placeholder="name@example.com"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                class="form-group col-sm"
              >
                <label className="profile-label">Gender</label>
                <select
                  style={{
                    width: "100%",
                    height: "35px",
                    outline: "none",
                    borderRadius: "5px",
                    border: "1px solid #ced4da",
                  }}
                  class="profile-input"
                  value={values.gender}
                  name="gender"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="male">male</option>
                  <option value="female">female</option>
                </select>
              </div>
            </div>
            <div className="row my-3">
              <div class="form-group col-md-6">
                <label className="profile-label">Email</label>
                <input
                  type="email"
                  className="form-control profile-input"
                  name="email"
                  onChange={handleChange}
                  required
                  // placeholder="name@example.com"
                />
              </div>
            </div>
            <div className="row my-3">
              <div class="form-group ">
                <label className="profile-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  onChange={handleChange}
                  // placeholder="name@example.com"
                />
              </div>
            </div>
            <div className="row my-3">
              <div class="form-group col">
                <label className="profile-label">City</label>
                <input
                  type="text"
                  className="form-control profile-input"
                  name="city"
                  onChange={handleChange}
                  // placeholder="name@example.com"
                />
              </div>
              <div class="form-group col">
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  class="form-group col-sm"
                >
                  <label className="profile-label">Country</label>
                  <select
                    style={{
                      width: "100%",
                      height: "35px",
                      outline: "none",
                      borderRadius: "5px",
                      border: "1px solid #ced4da",
                    }}
                    class="profile-input"
                    value={values.country}
                    name="country"
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    {countries?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div class="btn-container">
              <button type="submit" class="btn btn-primary">
                {isLoading ? "loading" : "Submit"}
              </button>
            </div>
          </form>
        </section>
      </div>
    </AuthBody>
  );
};

export default ProfileSetupPage;
