import React, { useState, useEffect } from "react";
import Body from "../Components/Body";
import Title from "../Components/Title";
import axios from "../../Api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../ContextApi/TKEDataProvider";
import "../Styles/Login.css";
const LOGIN_URL = "/user/login";

const Login = () => {
  const { setUserName1 } = useGlobalContext();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // console.log(values);
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { email, password } = values;

    try {
      const response = await axios.post(LOGIN_URL, { email, password });
      const { firstName } = response?.data?.data;
      // console.log(response?.data?.data?.membership);
      // benefits membershipType
      localStorage.setItem("data1", JSON.stringify(response?.data?.token));

      localStorage.setItem(
        "emailId",
        JSON.stringify(response?.data?.data?.email)
      );
      setValues({
        email: "",
        password: "",
      });

      setErrMsg(response?.msg);
      setIsLoading(false);
      // console.log(response?.data?.data?.banner?.link);
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
        setIsLoading(false);
      } else if (err.response?.status === 404) {
        setErrMsg("User does not exists");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading(false);
      }
    }
  };
  const authToken = JSON.parse(localStorage.getItem("data1"));
  useEffect(() => {
    if (authToken) {
      navigate("/");
    }
  }, [authToken]);

  return (
    <Body>
      <div className="login-container p-4">
        <Title title="Login" />
        <form className="px-2 login-container-form" onSubmit={handleSubmit}>
          <div className="mb-3 pt-3 login-input-wrapper">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              onChange={onChange}
            />
          </div>
          <div className="mb-3 pt-3 login-input-wrapper">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Password"
              onChange={onChange}
            />
          </div>
          <p style={{ color: "red" }}>{errMsg}</p>
          <Link to={"/forgot"}>
            <span className="login-forgot">Forgot password</span>
          </Link>
          <div className="btn-container-login">
            <button type="submit" className="btn btn-primary">
              {isLoading ? "Loading" : "Login"}
            </button>
          </div>
        </form>
      </div>
    </Body>
  );
};

export default Login;
